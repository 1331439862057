import React from "react";
import s from "./styles.module.css";
import { clsx } from "../../../helpers/classNameSpreader";

export const ButtonV2 = ({
  text,
  color,
  onClick,
  fit = null,
  disabled,
  type = "button",
}) => {
  return (
    <button
      type={type}
      onClick={type === "submit" ? undefined : onClick}
      disabled={disabled}
      className={clsx(s[color], s.dashboardButton, fit ? s.fit : "")}
    >
      {text}
    </button>
  );
};
