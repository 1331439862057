import React, { useEffect, useState } from "react";
import s from "./styles.module.css";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { HorizontalScrollWrapper } from "../HorizontalScrollWrapper/HorizontalScrollWrapper";
import { axiosWithToken, functionBaseUrl } from "../../../../common/firebase";
import { PositionSelect } from "../PositionSelect/PositionSelect";
import { Tooltip } from "@material-ui/core";

export const IndicatorSelectList = ({
  isOpen,
  values,
  onSave,
  onClose,
  user,
}) => {
  const [options, setOptions] = useState([]);
  const [tagFilter, setTagFilter] = useState("All");
  const [positionFilter, setPositionFilter] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (user) {
      (async () => {
        const subTypeToLevelMap = (type) => {
          if (type === "Admin") {
            return type;
          } else if (type.includes("free")) {
            return "Free";
          } else if (type.includes("student_lite")) {
            return "3D Practice";
          } else {
            return "Pro-Lite";
          }
        };

        const response = await axiosWithToken(
          //`http://localhost:5000/sportsbox-product-baseball/us-central1/api/utility/action-sdk-indicators?subscriptionLevel=${subTypeToLevelMap(
          `${functionBaseUrl}/api/utility/action-sdk-indicators?subscriptionLevel=${subTypeToLevelMap(
            "Admin"
          )}`,
          {
            method: "GET",
          }
        );
        setOptions(
          response.data.sort((a, b) => a.category.length - b.category.length)
        );
      })();
    }
  }, [user]);

  useEffect(() => {
    setSelectedValues(values.map((el) => el.id));
  }, [values]);

  const handleSelectValue = (value) => {
    setSelectedValues((prev) => {
      return prev.includes(value)
        ? prev.filter((el) => el !== value)
        : [...prev, value];
    });
  };

  const handleMultipleSelectValues = (values) => {
    setSelectedValues((prev) => {
      return [...prev, ...values.filter((el) => !prev.includes(el))];
    });
  };

  const handleApply = () => {
    const data = options.reduce((acc, el) => {
      return [...acc, ...el.items];
    }, []);

    const filtered = data.filter((el) => {
      return selectedValues.includes(el.id);
    });
    onSave(filtered);
    onClose();
  };

  const handleSelectAll = () => {
    if (positionFilter) {
      const filtered = options
        .filter((el) => {
          if (tagFilter === "All") {
            return true;
          } else {
            return el.category === tagFilter;
          }
        })
        .map((el) => {
          const items = el.items.filter((item) => {
            if (!positionFilter.length) {
              return true;
            } else {
              return positionFilter.includes(item.positionOrPhase);
            }
          });

          return items;
        });

      handleMultipleSelectValues(filtered.flat().map((it) => it.id));
    } else {
      handleMultipleSelectValues(
        options
          .reduce((acc, el) => {
            return [...acc, ...el.items];
          }, [])
          .map((it) => it.id)
      );
    }
  };

  const handleSelectAllCategory = (items) => {
    if (positionFilter) {
      const filtered = items.filter((item) => {
        if (!positionFilter.length) {
          return true;
        } else {
          return positionFilter.includes(item.positionOrPhase);
        }
      });

      handleMultipleSelectValues(filtered.map((it) => it.id));
    } else {
      handleMultipleSelectValues(items.map((it) => it.id));
    }
  };

  const handlePositionFilter = (value) => {
    setPositionFilter((prev) => {
      if (prev.includes(value)) {
        return prev.filter((el) => el !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  return (
    <div style={{ display: isOpen ? "block" : "none" }} className={s.container}>
      <div className={s.header}>
        <div>Add Data</div>
        <CloseOutlinedIcon style={{ cursor: "pointer" }} onClick={onClose} />
      </div>

      <div>Apply or remove data to the table</div>

      <div style={{ margin: "20px 0" }}>
        <HorizontalScrollWrapper width="300px">
          {[{ category: "All" }, ...options].map((el) => {
            return (
              <div
                onClick={() => setTagFilter(el.category)}
                className={`${s.tag} ${
                  tagFilter === el.category ? s.activeTag : ""
                }`}
              >
                {el.category}
              </div>
            );
          })}
        </HorizontalScrollWrapper>
      </div>

      {/*<div style={{ margin: "10px 0" }}>
        <PositionSelect
          values={positionFilter}
          name="positionFilter"
          onChange={handlePositionFilter}
          options={[
            { text: "Backswing", value: "Backswing" },
            { text: "Downswing", value: "Downswing" },
            { text: "Follow Through", value: "Follow Through" },
            { text: "Full Swing", value: "Full Swing" },
            { text: "IMP", value: "IMP" },
            { text: "ADR", value: "ADR" },
            { text: "TOP", value: "TOP" },
            { text: "FIN", value: "FIN" },
            { text: "DAH", value: "DAH" },
            { text: "BAH", value: "BAH" },
            { text: "D45", value: "D45" },
            { text: "BCH", value: "BCH" },
            { text: "DCH", value: "DCH" },
            { text: "FCH", value: "FCH" },
            { text: "DCV", value: "DCV" },
            { text: "BCV", value: "BCV" },
            { text: "F45", value: "F45" },
          ]}
          label="Position"
          emptyValue="None"
          optionsLabel="Positions"
        />
      </div>*/}

      <div className={s.categories}>
        {options
          .filter((el) => {
            if (tagFilter === "All") {
              return true;
            } else {
              return el.category === tagFilter;
            }
          })
          .map((el) => {
            const items = el.items?.filter((item) => {
              if (!positionFilter.length) {
                return true;
              } else {
                return positionFilter.includes(item.positionOrPhase);
              }
            });

            if (items?.length) {
              return (
                <div>
                  <div className={s.category}>
                    <div>{el.category}</div>
                    <div
                      onClick={() => handleSelectAllCategory(el.items)}
                      className={s.purpleTextButton}
                    >
                      + Add all
                    </div>
                  </div>

                  <div className={s.categoryItemsContainer}>
                    {items.map((el2) => {
                      return (
                        <div
                          className={s.categoryItem}
                          onClick={() => handleSelectValue(el2.id)}
                        >
                          {selectedValues.includes(el2.id) ? (
                            <CheckBoxIcon style={{ color: "#9e6cfc" }} />
                          ) : (
                            <CheckBoxOutlineBlankIcon
                              style={{ color: "#D0D5DD" }}
                            />
                          )}
                          <Tooltip
                            title={
                              el2?.description || "No description available"
                            }
                            PopperProps={{ style: { zIndex: 3000 } }}
                            placement="top-start"
                          >
                            <span>{el2.name}</span>
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
      </div>

      <div className={s.buttonsContainer}>
        <div
          style={
            options.reduce((acc, el) => {
              return [...acc, ...(el.items || [])];
            }, []).length !== selectedValues.length
              ? {}
              : { color: "#CDCFD1" }
          }
          onClick={() => handleSelectAll()}
          className={s.purpleTextButton}
        >
          include all
        </div>
        <div className={s.buttonsGroup}>
          <button onClick={onClose} className={s.cancelButton}>
            cancel
          </button>

          <button
            disabled={selectedValues.length ? false : true}
            onClick={handleApply}
            className={s.applyButton}
          >
            apply
          </button>
        </div>
      </div>
    </div>
  );
};
