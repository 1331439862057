import React from "react";
import s from "./style.module.css";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";

export const MainPageSwings = ({ count }) => {
  const cards = [
    { label: "Last 24 hours", value: count.last24Hours },
    { label: "Last 7 days", value: count.last7Days },
    { label: "Last 30 days", value: count.lastMonth },
    { label: "All Time", value: count.allTime },
  ];

  return (
    <div>
      <div className={s.header}>
        <AssessmentOutlinedIcon style={{ color: "#4009A5" }} />
        <div>Swings</div>
      </div>
      <div className={s.cardsContainer}>
        {cards.map((el) => {
          return (
            <div className={s.card}>
              <div className={s.cardPeriod}>{el.label}</div>
              <div className={s.cardValue}>{el.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
