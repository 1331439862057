import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";
import "firebase/messaging";
import "firebase/analytics";
import axios from "axios";

const envConfig = (devValue, prodValue) => {
  if (process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT") return devValue;
  if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") return prodValue;

  return devValue;
};

export const functionBaseUrl = envConfig(
  "https://us-central1-sportsbox-baseball.cloudfunctions.net",
  //"http://localhost:5000/sportsbox-baseball/us-central1",
  "https://us-central1-sportsbox-product-baseball.cloudfunctions.net"
  //"http://localhost:5000/sportsbox-product-baseball/us-central1"
);

export const websiteUrl = envConfig(
  "https://sportsbox-baseball.web.app",
  "https://sportsbox-product-baseball.web.app"
);

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = envConfig(
  {
    apiKey: "AIzaSyAtGgGyPx-0fd6qs6sAUI9FAw6-2BHJU5Q",
    authDomain: "sportsbox-baseball.firebaseapp.com",
    projectId: "sportsbox-baseball",
    storageBucket: "sportsbox-baseball.appspot.com",
    messagingSenderId: "922282699761",
    appId: "1:922282699761:web:f19d38c30592d2e7e53250",
    measurementId: "G-TGG3RMS7S5"
  },
  {
    apiKey: "AIzaSyCqGLQwEDyeBCwiF8C40xs3i7zZkvGO6Ps",
    authDomain: "sportsbox-product-baseball.firebaseapp.com",
    projectId: "sportsbox-product-baseball",
    storageBucket: "sportsbox-product-baseball.appspot.com",
    messagingSenderId: "747571247666",
    appId: "1:747571247666:web:0e1aee4004a74731b95a2b",
    measurementId: "G-JJT98VCQ7M"
  }
);

export const storageBucketURL = envConfig(
  "https://console.firebase.google.com/u/0/project/sportsbox-baseball/storage/sportsbox-baseball.appspot.com/files/~2Fswing_data/",
  "https://console.firebase.google.com/u/0/project/sportsbox-product-baseball/storage/sportsbox-product-baseball.appspot.com/files/~2Fswing_data/"
);

export const esIndex = envConfig(
  "baseball-dev",
  "baseball-prod"
);

export const riIndex = envConfig(
  "report_issues-baseball",
  "report_issues-baseball-prod"
);

export const stripeKey = envConfig(
  "pk_test_51JS3jaHK8QnjuqRNSlSVNOX9mD3Be02vIQZb2S5HHozM145GjpeVvuSHadBPcQSnJfHpZvPBnPmgHeFwXqjk7M5100Ez2ZTngL",
  "pk_live_51JS3jaHK8QnjuqRNKU8o7ZtGtUeF03nX0szre7AcvaiZYntkJrVweqbd12NNnSfkXonSTUFc33zAMhGNnr0PnHu400upbbHg6u"
);

export const loginWithGoogle = () => {
  let provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account",
  });
  return firebase.auth().signInWithPopup(provider);
};

export const loginWithFacebook = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  return firebase.auth().signInWithPopup(provider);
};

export const loginWithApple = () => {
  const provider = new firebase.auth.OAuthProvider("apple.com");
  return firebase.auth().signInWithPopup(provider);
};

export const axiosWithToken = async (url, options) => {
  const user = firebase.auth().currentUser;
  if (user) {
    const token = await user.getIdToken(true);
    return await axios(url, {
      ...options,
      withCredentials: false,
      headers: {
        authorization: "Bearer " + token,
      },
    });
  }

  const currentUser = localStorage.getItem("currUser");
  if (currentUser) {
    return await axios(url, {
      ...options,
      withCredentials: false,
      headers: {
        authorization: "Bearer " + currentUser,
      },
    });
  }
  throw new Error("User is not signed in");
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const perf = firebase.performance();

firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code === "failed-precondition") {
      console.log(err);
    } else if (err.code === "unimplemented") {
      console.log(err);
    }
  });

export default firebase;
