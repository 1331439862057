import React from "react";
import s from "./styles.module.css";
import { clsx } from "../../../helpers/classNameSpreader";

export const DashboardButton = ({
  text,
  color,
  onClick,
  fit = null,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={clsx(s[color], s.dashboardButton, fit ? s.fit : "")}
    >
      {text}
    </button>
  );
};
