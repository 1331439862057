import s from "./styles.module.css";
import React from "react";

export const DashboardInput = React.memo(
  ({
    onChange,
    name,
    value,
    onIconClick,
    placeholder,
    icon = null,
    label = null,
    width = "100%",
  }) => {
    return (
      <div style={{ width }}>
        {label && <div className={s.inputLabel}>{label}</div>}
        <div className={s.inputContainer}>
          {icon && <div onClick={onIconClick}>{icon}</div>}

          <input
            name={name}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            value={value}
          />
        </div>
      </div>
    );
  }
);
