import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, InputAdornment, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

import { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { listOfSubscriptions } from '../../common/envConfig';


function AddSubscriptionToUserComponent({ open, close }) {
  const [subEmail, setSubEmail] = useState('');
  const [subPlan, setSubPlan] = useState('');
  const [subPeriod, setSubPeriod] = useState(0);

  const validateEmail = (email) => {
    const verify = /\S+@\S+\.\S+/
    return verify.test(email)
  }

  const handleAdd = async () => {
    if (subEmail.length === 0) {
      Swal.fire('<p style="font-size:70%;">Please enter User Email</p>');
      return;
    }
    if (!validateEmail(subEmail)) {
      Swal.fire('<p style="font-size:70%;">Please enter a valid User Email</p>');
      return;
    }
    if (subPlan.length === 0) {
      Swal.fire('<p style="font-size:70%;">Please select a Subscription Plan</p>');
      return;
    }
    axiosWithToken(`${functionBaseUrl}/api/addSub`, {
      method: 'POST',
      data: {
        email: subEmail.toLowerCase().replace(/ /g, ''),
        plan: subPlan,
        months: (subPeriod >= 0) ? subPeriod : -subPeriod,
      }
    })
      .then(creds => {
        if (creds.status === 201) {
          Swal.fire({
            title: `<p style="font-size:70%;">${creds.data.message}</p>`,
            icon: 'warning',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
          })
        } else {
          Swal.fire({
            title: `<p style="font-size:70%;">${subPeriod} months of subscription successfully added to account: ${subEmail}</p>`,
            icon: 'success',
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            timer: 5000
          })
          setSubEmail('');
          setSubPlan('development_sportsbox_pro_lite_monthly');
          setSubPeriod(0);
          close();
        }
      })
      .catch(err => {
        console.log(err)
      });
  }

  return (<>
    <Dialog
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <div>
          Enter User Details
          <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="User Email"
              onChange={(event) => { setSubEmail(event.target.value) }}
              defaultValue={subEmail}
              variant="outlined"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>

          <Grid
            item
            sm={6}
            xs={12}
          >
            <FormControl
              variant="outlined"
              fullWidth
            >
              <InputLabel id="subPlan-select">Subscription Plan*</InputLabel>
              <Select
                id="subPlan"
                label="Subscription Plan"
                labelId="subPlan-select"
                name="subPlan"
                defaultValue={subPlan}
                onChange={(event) => { setSubPlan(event.target.value) }}
              >
                <MenuItem value={listOfSubscriptions.STUDENT_FREE}>Free</MenuItem>
                <MenuItem value={listOfSubscriptions.STUDENT_LITE_MONTHLY}>3D Practice</MenuItem>
                <MenuItem value={listOfSubscriptions.PREMIUM_MONTHLY}>3D Pro</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              fullWidth
              required
              label="Subscription Period"
              onChange={(event) => { setSubPeriod(event.target.value) }}
              defaultValue={subPeriod}
              variant="outlined"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">month(s)</InputAdornment>,
                inputProps: { min: 0 }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdd}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}

export const AddSubscriptionToUser = AddSubscriptionToUserComponent;
