import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import s from "./styles.module.css";
import { ButtonV2 } from "../../../../components/redesign-components/ButtonV2/ButtonV2";

export function ForesightPaymentSuccess({ isOpen, onClose }) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className={s.dialogContent}>
        <img src={"/images/logos/foresight_integration_large.png"} />
        <div className={s.dialogHeader}>
          <div className={s.dialogIconSecondary}>
            <div className={s.dialogIcon}>
              <CheckCircleOutlineOutlinedIcon />
            </div>
          </div>
          <div className={s.dialogHeaderText}>You are all set</div>
          <div>Start training now with your Foresight device</div>
        </div>

        <div className={s.dialogItem}>
          <div className={s.dialogItemNumber}>1</div>
          <div>Sign in to the app with your account</div>
        </div>
        <div className={s.dialogItem}>
          <div className={s.dialogItemNumber}>2</div>
          <div>Start a swing monitor session</div>
        </div>
        <div className={s.dialogItem}>
          <div className={s.dialogItemNumber}>3</div>
          <div>Pair your device with Sportsbox app</div>
        </div>
        <ButtonV2 text="Back to plan" color="purple" onClick={onClose} />
      </div>
    </Dialog>
  );
}
