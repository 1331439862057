import React from "react";
import s from "./styles.module.css";
import {
  Dialog,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { DashboardButton } from "../../DashboardButton";

export const ConfirmDialog = ({
  open,
  onClose,
  onSuccess,
  cancelText = "Cancel",
  submitText = "Submit",
  headerText = "Are you sure?",
  subText = "There is no going back.",
}) => {
  const handleSuccess = () => {
    onSuccess();
    onClose();
  };
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      {/* <div className={s.layout}> */}
      {/* <div className={s.iconBackground}>
          <PersonOutlineOutlinedIcon className={s.icon} />
        </div> */}

      <div style={{ padding: "20px 20px 10px" }}>
        <div className={s.headerContainer}>
          <div className={s.header}>{headerText}</div>
          <CloseIcon onClick={onClose} />
        </div>

        <div className={s.subText}>{subText}</div>
        {/* <div className={s.subText}>
          You can only work with this student if you invite them again
        </div> */}
      </div>
      {/* </div> */}

      <div className={s.buttonGroup}>
        <DashboardButton onClick={onClose} color="gray" text={cancelText} fit />
        <DashboardButton
          onClick={handleSuccess}
          color="red"
          text={submitText}
          fit
        />
      </div>
    </Dialog>
  );
};
