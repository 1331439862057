import React, { useCallback, useEffect, useState } from "react";
import { DashboardCard } from "./components/Card";
import { DashboardButton } from "./components/DashboardButton";
import s from "./styles.module.css";
import { DashboardStudentsTable } from "./components/DashboardStudentsTable";
import {
  disableAutoRenewal,
  enableAutoRenewal,
  getDashboardUser,
  getDashboardUserStudents,
  getEnterpriseCoaches,
  getRevenue,
  //removePendingStudent,
  removeStudent,
  upgradeStudent,
  verifyStaffEnt,
} from "./api";
import * as moment from "moment";
import { Link } from "react-router-dom";
import {
  CircularProgress,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  listOfSubscriptionNames,
  listOfSubscriptions,
} from "../../common/envConfig";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import Integration from "./Integration";
import NoSubscriptionDialog from "../../components/NoSubscriptionDialog";
import Swal from "sweetalert2";

export const StudentDashboardPage = () => {
  const [user, setUser] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    sort: "daysLeft",
    order: "desc",
  });
  const [tableMetaOptions, setTableMetaOptions] = useState({ total: 0 });
  const [tableData, setTableData] = useState([]);
  const [renewDialog, setRenewDialog] = useState({
    open: false,
    studentDetails: null,
    form: {
      subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
      subPeriod: 1,
      autoRenewal: false,
    },
  });
  const [integrationPopUpOpen, setIntegrationPopUpOpen] = useState(false);
  const [isStaffEnterprise, setIsStaffEnterprise] = useState(false);
  const [isCertified, setIsCertified] = useState(false);
  const [enterpriseCoaches, setEnterpriseCoaches] = useState([]);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const apiData = await getDashboardUser();
      if (window.location.href.toLowerCase().includes("partner=golfgenius")) {
        setIntegrationPopUpOpen(true);
      }
      if (apiData.subscriptionType.includes("student")) {
        if (window.location.href.includes("?")) {
          history.push(
            "/student-dashboard?" + window.location.href.split("?")[1]
          );
        } else {
          history.push("/student-dashboard");
        }
      }

      const revenueData = await getRevenue();
      setInvoices(revenueData);
      setUser(apiData);

      if (
        apiData?.subscriptionType.includes("enterprise_master") &&
        apiData?.enterpriseAccountId
      ) {
        const enterpriseData = await verifyStaffEnt(apiData?.id);
        const coaches = await getEnterpriseCoaches();
        setIsStaffEnterprise(enterpriseData?.isStaffEnterprise);
        setIsCertified(enterpriseData?.isCertified);
        setOnboardingCompleted(enterpriseData?.onboardingCompleted);
        setEnterpriseCoaches(coaches);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const apiData = await getDashboardUserStudents({
        page: tableOptions.page,
        sort: tableOptions.sort,
        order: tableOptions.order,
        search: "",
        subscriptionFilter: null,
      });

      setTableData(
        apiData.result.map((res) => ({
          name: `${res.firstName} ${res.lastName}`,
          plan: res.subscriptionType,
          daysLeft: res.daysLeft,
          subId: res.subscription?.id ? res.subscription?.id : null,
          email: res.email,
          sessions: res.sessionIds.length,
          status: res.invite?.status === "approved" ? true : false,
          inviteId: res.invite?.id ? res.invite?.id : null,
          toAutoRenew:
            res.subscription?.platform &&
            res.subscription?.platform === "Credits"
              ? true
              : false,
          autoRenew: res.subscription?.autoRenewal
            ? res.subscription?.autoRenewal
            : false,
          id: res.id,
        }))
      );
      setTableMetaOptions({ total: apiData.total });
    })();
  }, [tableOptions]);

  const handleSort = (field) => {
    setTableOptions((prev) => {
      const newState = {
        sort: field,
        page: prev.page,
        order:
          prev.sort === field
            ? prev.order === "asc"
              ? "desc"
              : "asc"
            : "desc",
      };
      return newState;
    });
  };

  const handleStudentRemove = useCallback(async (id) => {
    await removeStudent(id);
    setTableOptions({
      page: 1,
      sort: "firstName",
      order: "desc",
    });
  }, []);

  const handlePendingStudentRemove = useCallback(async (id) => {
    await removeStudent(id);
    setTableOptions({
      page: 1,
      sort: "firstName",
      order: "desc",
    });
  }, []);

  const handleRenewDialog = (studentDetails) => {
    setRenewDialog({
      open: true,
      studentDetails,
      form: {
        subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
        subPeriod: 1,
        autoRenewal: false,
      },
    });
  };

  const handleRenewDialogClose = () => {
    setRenewDialog({
      open: false,
      studentDetails: null,
      form: {
        subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
        subPeriod: 1,
        autoRenewal: false,
      },
    });
  };

  const handleRenewDialogForm = (field, value) => {
    setRenewDialog((prev) => ({
      ...prev,
      form: { ...prev.form, [field]: value },
    }));
  };

  const handleRenewDialogSubmit = useCallback(async () => {
    let message = "Student upgraded successfully!";
    if (renewDialog.open) {
      message = await upgradeStudent({
        studentId: renewDialog.studentDetails.id,
        coachId: user.id,
        coachName: user.firstName + " " + user.lastName,
        subPlan: renewDialog.form.subPlan,
        subPeriod: renewDialog.form.subPeriod,
        autoRenewal: renewDialog.form.autoRenewal,
      });
    }
    handleRenewDialogClose();
    updateDataAfterRenewal();
    Swal.fire({
      icon: "info",
      text: message,
      showConfirmButton: false,
      showCloseButton: true,
      timer: 3000,
      customClass: {
        container: "my-swal",
      },
    });
  }, [renewDialog, user]);

  const handleAutoRenewalSwitch = useCallback(
    async (subId, isToSetOn) => {
      if (isToSetOn) {
        await enableAutoRenewal({
          subId,
          coachId: user.id,
        });
      } else {
        await disableAutoRenewal({
          subId,
          coachId: user.id,
        });
      }
      Swal.fire({
        icon: "success",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 2000,
        customClass: {
          container: "my-swal",
        },
      });
      setTableOptions((prev) => ({ ...prev }));
    },
    [user]
  );

  const handlePagination = (nextPage) => {
    setTableOptions((prev) => ({ ...prev, page: nextPage }));
  };

  const milisecToDays = (start, end) => Math.ceil((end - start) / 86400000);

  const upcomingAutoRenevals = (() => {
    if (tableData.length) {
      return tableData.reduce((acc, el) => {
        if (el.daysLeft <= 30 && el?.autoRenew) {
          return acc + 1;
        }
        return acc;
      }, 0);
    }
    return 0;
  })();

  const handleIntegrationPopupClose = () => {
    setIntegrationPopUpOpen(false);
  };

  const updateDataAfterRenewal = async () => {
    Swal.fire({
      icon: "success",
      showConfirmButton: false,
      showCloseButton: true,
      timer: 2000,
      customClass: {
        container: "my-swal",
      },
    });
    const userData = await getDashboardUser();
    setUser(userData);

    const apiData = await getDashboardUserStudents({
      page: tableOptions.page,
      sort: tableOptions.sort,
      order: tableOptions.order,
      search: "",
      subscriptionFilter: null,
    });

    setTableData(
      apiData.result.map((res) => ({
        name: `${res.firstName} ${res.lastName}`,
        plan: res.subscriptionType,
        daysLeft: res.daysLeft,
        subId: res.subscription?.id ? res.subscription?.id : null,
        email: res.email,
        sessions: res.sessionIds.length,
        status: res.invite?.status === "approved" ? true : false,
        inviteId: res.invite?.id ? res.invite?.id : null,
        toAutoRenew:
          res.subscription?.platform && res.subscription?.platform === "Credits"
            ? true
            : false,
        autoRenew: res.subscription?.autoRenewal
          ? res.subscription?.autoRenewal
          : false,
        id: res.id,
      }))
    );
    setTableMetaOptions({ total: apiData.total });
  };

  const Loader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  };

  return (
    <div style={{ padding: "0 20px" }}>
      <div className={s.header}>
        Welcome back{user && `, ${user.firstName}`}! 👋🏻
      </div>
      <div className={s.cardContainer}>
        <DashboardCard>
          {user ? (
            <>
              <div className={s.cardGrayText}>Credits remaining</div>
              <div className={s.cardBoldText}>
                {user && user.creditCount ? user.creditCount : 0}
              </div>
              <div className={s.cardGrayBoldText}>
                {upcomingAutoRenevals} upcoming auto-renewals
              </div>
              <div className={s.cardGrayText}>Within the next 30 days.</div>
              <hr />
              <Link to="/credits">
                <DashboardButton color="blue" text="Buy" />
              </Link>
            </>
          ) : (
            <Loader />
          )}
        </DashboardCard>
        <DashboardCard>
          {invoices ? (
            <>
              <div className={s.cardGrayText}>Invoices</div>
              <div className={s.cardBoldText}>${invoices.total}</div>
              <div className={s.cardGrayText}>
                Revenue share: {invoices.revenue}
              </div>
              <hr />
              <Link to="/enterprise/pay/students">
                <DashboardButton color="gray" text="Manage Invoices" />
              </Link>
            </>
          ) : (
            <Loader />
          )}
        </DashboardCard>
        <DashboardCard>
          {user ? (
            <>
              <div className={s.cardGrayText}>Current plan</div>
              <div className={s.cardBoldText}>
                {user && listOfSubscriptionNames[user.subscriptionType]}
              </div>
              <div className={s.cardGrayText}>
                {user && moment(+user.sub.endDate).format("MMM DD, YYYY")} -{" "}
                {user && milisecToDays(Date.now(), user.sub.endDate)} days left
              </div>
              <hr />
              <Link to="/account">
                <DashboardButton color="gray" text="Manage" />
              </Link>
            </>
          ) : (
            <Loader />
          )}
        </DashboardCard>
      </div>
      <div className={s.header}>
        <div>Expiring Subscriptions </div>
        <Link to="/students">
          <DashboardButton fit color="gray" text="Students" />
        </Link>
      </div>
      <DashboardStudentsTable
        onSort={handleSort}
        onPagination={handlePagination}
        tableOptions={tableOptions}
        total={tableMetaOptions.total}
        data={tableData}
        onRemove={handleStudentRemove}
        onRemovePending={handlePendingStudentRemove}
        onRenew={handleRenewDialog}
        onRenewSwitch={handleAutoRenewalSwitch}
      />
      <NoSubscriptionDialog
        activeSub={user?.sub?.endDate <= Date.now() ? false : true}
        role={user?.role}
        subscriptionType={user?.subscriptionType}
        isStaffEnterprise={isStaffEnterprise}
        isCertified={isCertified}
        onboardingCompleted={onboardingCompleted}
        loading={true}
      />
      {integrationPopUpOpen && (
        <Integration
          open={integrationPopUpOpen}
          onClose={handleIntegrationPopupClose}
          subscriptionType={user?.subscriptionType}
          uid={user?.id}
        />
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={renewDialog.open}
        onClose={handleRenewDialogClose}
      >
        <DialogTitle id="simple-dialog-title">Renew subscription</DialogTitle>
        <div className={s.renewDialogContent}>
          <TextField
            fullWidth
            disabled
            label="Name"
            name="name"
            margin="dense"
            value={renewDialog.studentDetails?.name}
            variant="outlined"
          />
          <TextField
            fullWidth
            disabled
            label="Email"
            name="email"
            margin="dense"
            value={renewDialog.studentDetails?.email}
            variant="outlined"
          />
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="subPlan-select">Account Type*</InputLabel>
            <Select
              id="subPlan"
              label="Account Type*"
              labelId="subPlan-select"
              name="subPlan"
              value={renewDialog.form.subPlan}
              onChange={(e) => handleRenewDialogForm("subPlan", e.target.value)}
            >
              <MenuItem value={listOfSubscriptions.STUDENT_LITE_MONTHLY}>
                3D Player Monthly
              </MenuItem>
              <MenuItem value={listOfSubscriptions.STUDENT_LITE_ANNUALLY}>
                3D Player Annually
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Subscription Period*"
            name="subPeriod"
            margin="dense"
            onChange={(e) => handleRenewDialogForm("subPeriod", e.target.value)}
            value={renewDialog.form.subPeriod}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {renewDialog.form.subPlan.includes("annually")
                    ? "years"
                    : "months"}
                </InputAdornment>
              ),
              inputProps: { min: 0 },
            }}
            type="number"
            variant="outlined"
          />
          {renewDialog.form.subPeriod !== "" &&
            renewDialog.form.subPeriod <= 0 && (
              <Typography style={{ color: "red" }}>
                Subscription Period has to be greater than 0
              </Typography>
            )}

          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="autoRenewal-select">Auto Renewal*</InputLabel>
            <Select
              id="autoRenewal"
              label="Auto Renewal*"
              labelId="autoRenewal-select"
              name="autoRenewal"
              value={renewDialog.form.autoRenewal}
              onChange={(e) =>
                handleRenewDialogForm("autoRenewal", e.target.value)
              }
            >
              <MenuItem value={true}>On</MenuItem>
              <MenuItem value={false}>Off</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={s.renewDialogButtons}>
          <DashboardButton
            onClick={handleRenewDialogClose}
            color="gray"
            text="Cancel"
            fit
          />
          <DashboardButton
            onClick={handleRenewDialogSubmit}
            disabled={
              renewDialog.form.subPlan.length <= 0 ||
              renewDialog.form.subPeriod <= 0
            }
            color="blue"
            text="Submit"
            fit
          />
        </div>
      </Dialog>
    </div>
  );
};
