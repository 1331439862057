import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import s from "./styles.module.css";

export const DashboardSelect = ({
  value,
  name,
  onChange,
  options,
  label,
  width = "100%",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (value) => {
    onChange(name, value);
    setIsOpen(false);
  };

  return (
    <div className={s.container} style={{ width }}>
      <div className={s.selectLabel}>{label}</div>
      <div className={s.selectWrapper} onClick={() => setIsOpen(!isOpen)}>
        <div className={s.selectValue}>
          <span>{options.find((el) => el.value === value)?.text}</span>
          <KeyboardArrowDownIcon />
        </div>
        <div
          className={isOpen ? `${s.options} ${s.optionsVisible}` : s.options}
        >
          {options.map((el) => (
            <div
              key={el.value}
              className={s.option}
              onClick={() => handleOptionClick(el.value)}
            >
              {el.text}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
