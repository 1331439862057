import React, { useCallback, useEffect, useRef, useState } from "react";
import { DashboardButton } from "./components/DashboardButton";
import s from "./styles.module.css";
import {
  assignCoachToStudent,
  disableAutoRenewal,
  enableAutoRenewal,
  getCoachesForEnterpriseMaster,
  getDashboardUser,
  getDashboardUserStudents,
  getEnterpriseCoaches,
  getEnterpriseMasterStudents,
  getStudioCoaches,
  getStudioMasterStudents,
  //removePendingStudent,
  removeStudent,
  unassignCoachToStudent,
  updateUserInfo,
  upgradeStudent,
  verifyStaffEnt,
} from "./api";
import SearchIcon from "@material-ui/icons/Search";
import { StudentsTable } from "./components/StudentsTable";
import { DashboardInput } from "./components/search-input/SearchInput";
import { AddStudentDialog } from "./components/dialogs/AddStudentDialog";
import { listOfSubscriptions } from "../../common/envConfig";
import { CircularProgress } from "@material-ui/core";
import { ImportStudentsEnterprise } from "../students/ImportStudentsEnterprise";
import { UpgradeStudentDialog } from "./components/dialogs/UpgradeStudentDialog/UpgradeStudentDialog";
import { EditStudentDialog } from "./components/dialogs/EditStudentDialog/EditStudentDialog";
import Swal from "sweetalert2";
import { ConfirmDialog } from "./components/dialogs/ConfirmDialog/ConfirmDialog";

export const StudentsPage = () => {
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState("");
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    sort: "daysLeft",
    order: "desc",
    subscriptionFilter: "",
  });
  const [tableMetaOptions, setTableMetaOptions] = useState({
    total: 0,
    isLoading: false,
  });
  const [tableData, setTableData] = useState([]);
  const [addDialog, setAddDialog] = useState({
    open: false,
    details: null,
    form: {},
  });

  const [renewDialog, setRenewDialog] = useState({
    open: false,
    studentDetails: null,
    form: {
      subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
      subPeriod: 1,
      autoRenewal: false,
    },
  });

  const [editStudentDialog, setEditStudentDialog] = useState({
    open: false,
    firstName: "",
    lastName: "",
    email: "",
    height: "",
    dominantHand: "",
    gender: "",
    id: null,
    status: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    onSuccess: () => "",
  });

  const [importStudentsDialog, setImportStudentsDialog] = useState(false);

  const [isStaffEnterprise, setIsStaffEnterprise] = useState(false);
  const [enterpriseCoaches, setEnterpriseCoaches] = useState([]);
  const [studioCoaches, setStudioCoaches] = useState([]);

  const searchRef = useRef(search);

  useEffect(() => {
    (async () => {
      const apiData = await getDashboardUser();
      setUser(apiData);

      if (
        apiData?.subscriptionType.includes("enterprise_master") &&
        apiData?.enterpriseAccountId
      ) {
        const enterpriseData = await verifyStaffEnt(apiData?.id);
        const coaches = await getEnterpriseCoaches(apiData.enterpriseAccountId);
        setIsStaffEnterprise(enterpriseData?.isStaffEnterprise);
        setEnterpriseCoaches(coaches);
      }

      if (
        apiData?.subscriptionType.includes("studio_master") &&
        apiData?.studioAccountId
      ) {
        const coaches = await getStudioCoaches(apiData.studioAccountId);
        setStudioCoaches(coaches);
      }
    })();
  }, []);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  useEffect(() => {
    (async () => {
      if (user) {
        setTableMetaOptions({ total: 0, isLoading: true });
        let apiData = null;

        if (
          user.subscriptionType.includes("enterprise_master") &&
          user.enterpriseAccountId
        ) {
          apiData = await getEnterpriseMasterStudents({
            enterpriseAccountId: user.enterpriseAccountId,
            search: searchRef.current,
            page: tableOptions.page,
            sort: tableOptions.sort,
            order: tableOptions.order,
            subscriptionFilter: tableOptions.subscriptionFilter
              ? tableOptions.subscriptionFilter.split(":")
              : "",
          });
        } else if (
          user.subscriptionType.includes("studio_master") &&
          user?.studioAccountId
        ) {
          apiData = await getStudioMasterStudents({
            studioAccountId: user.studioAccountId,
            search: searchRef.current,
            page: tableOptions.page,
            sort: tableOptions.sort,
            order: tableOptions.order,
            subscriptionFilter: tableOptions.subscriptionFilter
              ? tableOptions.subscriptionFilter.split(":")
              : "",
          });
        } else {
          apiData = await getDashboardUserStudents({
            search: searchRef.current,
            page: tableOptions.page,
            sort: tableOptions.sort,
            order: tableOptions.order,
            subscriptionFilter: tableOptions.subscriptionFilter
              ? tableOptions.subscriptionFilter.split(":")
              : "",
          });
        }

        const mappedStudents = apiData.result.map((res) => ({
          name: `${res.firstName} ${res.lastName}`,
          plan: res.subscriptionType,
          daysLeft: res.daysLeft,
          subId: res.subscription?.id ? res.subscription?.id : null,
          email: res.email,
          sessions: res.sessionIds.length,
          status: res.invite?.status === "approved" ? true : false,
          inviteId: res.invite?.id ? res.invite?.id : null,
          height: res.height,
          dominantHand: res.dominantHand,
          gender: res.gender,
          coaches: res.coaches,
          toAutoRenew:
            res.subscription?.platform &&
            res.subscription?.platform === "Credits"
              ? true
              : false,
          autoRenew: res.subscription?.autoRenewal
            ? res.subscription?.autoRenewal
            : false,
          id: res.id,
        }));

        setTableData(mappedStudents);
        setTableMetaOptions({ total: apiData.total, isLoading: false });
      }
    })();
  }, [tableOptions, user]);

  const handleSort = (field) => {
    setTableOptions((prev) => {
      const newState = {
        sort: field,
        page: prev.page,
        order:
          prev.sort === field
            ? prev.order === "asc"
              ? "desc"
              : "asc"
            : "desc",
        subscriptionFilter: prev.subscriptionFilter,
      };
      return newState;
    });
  };

  const handleStudentRemove = useCallback(async (id) => {
    setConfirmDialog({
      open: true,
      onSuccess: async () => {
        await removeStudent(id);
        setTableOptions({
          page: 1,
          sort: "firstName",
          order: "desc",
          subscriptionFilter: "",
        });
      },
    });
  }, []);

  const handlePendingStudentRemove = useCallback(async (id) => {
    setConfirmDialog({
      open: true,
      onSuccess: async () => {
        await removeStudent(id);
        setTableOptions({
          page: 1,
          sort: "firstName",
          order: "desc",
          subscriptionFilter: "",
        });
      },
    });
  }, []);

  const handleAddDialogOpen = () => {
    setAddDialog({
      open: true,
      details: null,
      form: {},
    });
  };

  const handleAddDialogClose = () => {
    setAddDialog({
      open: false,
      details: null,
      form: {},
    });
  };

  const handleAddDialogCreate = useCallback(async () => {
    setAddDialog({
      open: false,
      details: null,
      form: {},
    });

    setTableMetaOptions({ total: 0, isLoading: true });
    const apiData = await getDashboardUserStudents({
      search: searchRef.current,
      page: 1,
      sort: tableOptions.sort,
      order: tableOptions.order,
      subscriptionFilter: tableOptions.subscriptionFilter
        ? tableOptions.subscriptionFilter.split(":")
        : null,
    });

    setTableData(
      apiData.result.map((res) => ({
        name: `${res.firstName} ${res.lastName}`,
        plan: res.subscriptionType,
        daysLeft: res.daysLeft,
        subId: res.subscription?.id ? res.subscription?.id : null,
        email: res.email,
        sessions: res.sessionIds.length,
        status: res.invite?.status === "approved" ? true : false,
        height: res.height,
        dominantHand: res.dominantHand,
        gender: res.gender,
        coaches: res.coaches,
        toAutoRenew:
          res.subscription?.platform && res.subscription?.platform === "Credits"
            ? true
            : false,
        autoRenew: res.subscription?.autoRenewal
          ? res.subscription?.autoRenewal
          : false,
        id: res.id,
      }))
    );
    setTableMetaOptions({ total: apiData.total, isLoading: false });
  }, [tableOptions]);

  const handleSubFilter = (id) => {
    setTableOptions((prev) => ({ ...prev, subscriptionFilter: id }));
  };

  const handlePagination = (nextPage) => {
    setTableOptions((prev) => ({ ...prev, page: nextPage }));
  };

  const handleSearch = useCallback(async () => {
    setTableMetaOptions({ total: 0, isLoading: true });
    const apiData = await getDashboardUserStudents({
      search: searchRef.current,
      page: 1,
      sort: tableOptions.sort,
      order: tableOptions.order,
      subscriptionFilter: tableOptions.subscriptionFilter
        ? tableOptions.subscriptionFilter.split(":")
        : null,
    });

    setTableData(
      apiData.result.map((res) => ({
        name: `${res.firstName} ${res.lastName}`,
        plan: res.subscriptionType,
        daysLeft: res.daysLeft,
        subId: res.subscription?.id ? res.subscription?.id : null,
        email: res.email,
        sessions: res.sessionIds.length,
        status: res.invite?.status === "approved" ? true : false,
        height: res.height,
        dominantHand: res.dominantHand,
        gender: res.gender,
        coaches: res.coaches,
        toAutoRenew:
          res.subscription?.platform && res.subscription?.platform === "Credits"
            ? true
            : false,
        autoRenew: res.subscription?.autoRenewal
          ? res.subscription?.autoRenewal
          : false,
        id: res.id,
      }))
    );
    setTableMetaOptions({ total: apiData.total, isLoading: false });
  }, [tableOptions]);

  const handleRenewDialog = (studentDetails) => {
    setRenewDialog({
      open: true,
      studentDetails,
      form: {
        subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
        subPeriod: 1,
        autoRenewal: false,
      },
    });
  };

  const handleRenewDialogClose = () => {
    setRenewDialog({
      open: false,
      studentDetails: null,
      form: {
        subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
        subPeriod: 1,
        autoRenewal: false,
      },
    });
  };

  const handleRenewDialogForm = (field, value) => {
    setRenewDialog((prev) => ({
      ...prev,
      form: { ...prev.form, [field]: value },
    }));
  };

  const handleRenewDialogSubmit = useCallback(async () => {
    let message = "Student upgraded successfully!";
    if (renewDialog.open) {
      message = await upgradeStudent({
        studentId: renewDialog.studentDetails.id,
        coachId: user.id,
        coachName: user.firstName + " " + user.lastName,
        subPlan: renewDialog.form.subPlan,
        subPeriod: renewDialog.form.subPeriod,
        autoRenewal: renewDialog.form.autoRenewal,
      });
    }
    handleRenewDialogClose();
    Swal.fire({
      icon: "info",
      text: message,
      showConfirmButton: false,
      showCloseButton: true,
      timer: 3000,
      customClass: {
        container: "my-swal",
      },
    });
    setTableOptions((prev) => ({ ...prev }));
  }, [renewDialog, user]);

  const handleEditStudentDialogOpen = (
    id,
    name,
    email,
    height,
    dominantHand,
    gender,
    status
  ) =>
    setEditStudentDialog({
      id,
      firstName: name.split(" ")[0],
      lastName: name.split(" ")[1],
      email,
      height,
      dominantHand,
      gender,
      open: true,
      status,
    });

  const handleEditStudentDialogClose = () =>
    setEditStudentDialog((prev) => ({ ...prev, open: false }));

  const handleEditStudentDialogForm = (field, value) =>
    setEditStudentDialog((prev) => ({ ...prev, [field]: value }));

  const handleEditStudentDialogSubmit = useCallback(async () => {
    await updateUserInfo(
      editStudentDialog.id,
      editStudentDialog.firstName,
      editStudentDialog.lastName,
      editStudentDialog.email,
      editStudentDialog.height,
      editStudentDialog.dominantHand,
      editStudentDialog.gender
    );
    handleEditStudentDialogClose();
    setTableOptions((prev) => ({ ...prev }));
  }, [editStudentDialog]);

  const handleAutoRenewalSwitch = useCallback(
    async (subId, isToSetOn) => {
      if (isToSetOn) {
        await enableAutoRenewal({
          subId,
          coachId: user.id,
        });
      } else {
        await disableAutoRenewal({
          subId,
          coachId: user.id,
        });
      }
      Swal.fire({
        icon: "success",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 2000,
        customClass: {
          container: "my-swal",
        },
      });
      setTableOptions((prev) => ({ ...prev }));
    },
    [user]
  );

  const updateDataAfterRenewal = async () => {
    Swal.fire({
      icon: "success",
      showConfirmButton: false,
      showCloseButton: true,
      timer: 2000,
      customClass: {
        container: "my-swal",
      },
    });
    const userData = await getDashboardUser();
    setUser(userData);

    setTableMetaOptions({ total: 0, isLoading: true });
    const apiData = await getDashboardUserStudents({
      search: searchRef.current,
      page: tableOptions.page,
      sort: tableOptions.sort,
      order: tableOptions.order,
      subscriptionFilter: tableOptions.subscriptionFilter
        ? tableOptions.subscriptionFilter.split(":")
        : null,
    });

    setTableData(
      apiData.result.map((res) => ({
        name: `${res.firstName} ${res.lastName}`,
        plan: res.subscriptionType,
        daysLeft: res.daysLeft,
        subId: res.subscription?.id ? res.subscription?.id : null,
        email: res.email,
        sessions: res.sessionIds.length,
        status: res.invite?.status === "approved" ? true : false,
        inviteId: res.invite?.id ? res.invite?.id : null,
        coaches: res.coaches,
        toAutoRenew:
          res.subscription?.platform && res.subscription?.platform === "Credits"
            ? true
            : false,
        autoRenew: res.subscription?.autoRenewal
          ? res.subscription?.autoRenewal
          : false,
        id: res.id,
      }))
    );
    setTableMetaOptions({ total: apiData.total, isLoading: false });
  };

  const onCoachClick = useCallback(
    async (studentId, coachId, studentCoaches) => {
      if (studentCoaches.includes(coachId)) {
        await unassignCoachToStudent(coachId, studentId);
      } else {
        await assignCoachToStudent(coachId, studentId);
      }
      setTableOptions((prev) => ({ ...prev }));
    },
    []
  );

  const Loader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  };

  return (
    <div style={{ padding: "0 20px" }}>
      <div className={s.header}>
        <div>Students</div>
      </div>
      <div className={s.headerButtonGroup}>
        <DashboardButton
          onClick={handleAddDialogOpen}
          color="blue"
          text="Add student"
          fit
        />
        <DashboardButton
          color="gray"
          text="Import Students"
          fit
          onClick={() => setImportStudentsDialog(true)}
        />
      </div>
      <DashboardInput
        onChange={(_, value) => setSearch(value)}
        name="search"
        value={search}
        onIconClick={handleSearch}
        width="200px"
        icon={<SearchIcon fontSize="10px" />}
      />
      <div style={{ height: "10px" }} />
      <div className={s.subscriptionFilterContainer}>
        <div
          className={tableOptions.subscriptionFilter === null && s.selected}
          onClick={() => handleSubFilter(null)}
        >
          All
        </div>
        {/*<div
          className={
            tableOptions.subscriptionFilter ===
              listOfSubscriptions.STUDENT_FREE && s.selected
          }
          onClick={() => handleSubFilter(listOfSubscriptions.STUDENT_FREE)}
        >
          Free
        </div>
        <div
          className={
            tableOptions.subscriptionFilter ===
              `${listOfSubscriptions.STUDENT_LITE_MONTHLY}:${listOfSubscriptions.STUDENT_LITE_ANNUALLY}` &&
            s.selected
          }
          onClick={() =>
            handleSubFilter(
              `${listOfSubscriptions.STUDENT_LITE_MONTHLY}:${listOfSubscriptions.STUDENT_LITE_ANNUALLY}`
            )
          }
        >
          3D Player
        </div>*/}
      </div>
      <hr style={{ margin: "10px 0 20px" }} />
      {tableMetaOptions.isLoading ? (
        <Loader />
      ) : (
        <StudentsTable
          onSort={handleSort}
          onPagination={handlePagination}
          tableOptions={tableOptions}
          total={tableMetaOptions.total}
          data={tableData}
          onRemove={handleStudentRemove}
          onRemovePending={handlePendingStudentRemove}
          onRenew={handleRenewDialog}
          onRenewSwitch={handleAutoRenewalSwitch}
          onEditStudent={handleEditStudentDialogOpen}
          onCoachClick={onCoachClick}
          isEnterprise={
            user?.subscriptionType?.includes("enterprise_master") ? true : false
          }
          enterpriseCoaches={
            user ? [{ ...user, uid: user.id }, ...enterpriseCoaches] : []
          }
          isStudio={
            user?.subscriptionType?.includes("studio_master") ? true : false
          }
          studioCoaches={
            user ? [{ ...user, uid: user.id }, ...studioCoaches] : []
          }
        />
      )}

      <AddStudentDialog
        onClose={handleAddDialogClose}
        onCreate={handleAddDialogCreate}
        open={addDialog.open}
        credits={user?.creditCount ? user?.creditCount : 0}
      />

      <UpgradeStudentDialog
        open={renewDialog.open}
        form={renewDialog.form}
        studentDetails={renewDialog.studentDetails}
        onClose={handleRenewDialogClose}
        onSubmit={handleRenewDialogSubmit}
        onFormChange={handleRenewDialogForm}
      />

      <EditStudentDialog
        open={editStudentDialog.open}
        form={{
          firstName: editStudentDialog.firstName,
          lastName: editStudentDialog.lastName,
          email: editStudentDialog.email,
          height: editStudentDialog.height,
          dominantHand: editStudentDialog.dominantHand,
          gender: editStudentDialog.gender,
        }}
        status={editStudentDialog.status}
        studentId={editStudentDialog.id}
        onClose={handleEditStudentDialogClose}
        onSubmit={handleEditStudentDialogSubmit}
        onFormChange={handleEditStudentDialogForm}
      />

      <ConfirmDialog
        cancelText="Nevermind"
        submitText="Yes, remove student"
        subText={`You will not be able to interact with this student after removing it. \n You can only work with this student if you invite them again`}
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, onSuccess: () => "" })}
        onSuccess={confirmDialog.onSuccess}
      />

      {user && <ImportStudentsEnterprise
        open={importStudentsDialog}
        close={() => setImportStudentsDialog(false)}
        uid={user ? user.id : ""}
        name={user ? user.firstName : ""}
        admin={true}
        userEmail={user ? user.email : ""}
      />}
    </div>
  );
};
