import { FormControlLabel, Switch, withStyles } from "@material-ui/core";
import React from "react";

const IOSSwitchStyles = withStyles((theme) => ({
  root: {
    width: 34,
    height: 20,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(14px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#4009A5",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#4009A5",
      border: "5px solid #fff",
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 20 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const IOSSwitch = ({ checked, onChange }) => {
  return (
    <FormControlLabel
      control={
        <IOSSwitchStyles checked={checked} onChange={onChange} name="switch" />
      }
    />
  );
};
