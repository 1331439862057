import React from "react";
import s from "./tabs.module.css";

export const AccountDetailsTabs = ({ onChange, selectedView }) => {
  return (
    <div className={s.tabContainer}>
      <div
        onClick={() => onChange("details")}
        className={`${s.tab} ${
          selectedView === "details" ? s.selectedTab : ""
        }`}
      >
        My details
      </div>
      <div
        onClick={() => onChange("plan")}
        className={`${s.tab}  ${
          selectedView !== "details" ? s.selectedTab : ""
        }`}
      >
        Plan
      </div>
    </div>
  );
};
