import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import Swal from 'sweetalert2';

class Integration extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = async () => {
    const url = window.location.href;
    if (url.includes('partner') && url.includes('userId')) {
      const params = {};
      const query = url.split("?")[1];
      const pairs = query.split("&");

      pairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        params[key] = value;
      });

      if (params.userType === 'student') {
        if (this.props.subscriptionType && !this.props.subscriptionType.includes('student')) {
          Swal.fire({
            title: `<p style="font-size:70%;">You are not subscribed to a student plan. Please update your plan to integrate with Golf Genius.</p>`,
            icon: 'warning',
            showConfirmButton: false,
            showCloseButton: true,
            customClass: {
              container: 'my-swal'
            },
          })
          this.props.onClose();
          return;
        }
      } else if (params.userType === 'coach') {
          if (this.props.subscriptionType && this.props.subscriptionType.includes('student')) {
            Swal.fire({
              title: `<p style="font-size:70%;">You are not subscribed to a coach plan. Please upgrade your plan to integrate with Golf Genius.</p>`,
              icon: 'warning',
              showConfirmButton: false,
              showCloseButton: true,
              customClass: {
                container: 'my-swal'
              },
            })
            this.props.onClose();
            return;
          }
      }

      if (url.includes('userId')) {
        await axiosWithToken(functionBaseUrl + '/api/users/'+ this.props.uid, {
          method: 'PATCH',
          data: {    
            userInfo: {    
              golfGenius: { uid: params.userId, isActive: true },
              ggUserId: params.userId,
            }
          }
        })
          .catch((error) => {
            console.log(error)
          })
      }

    }

    const encryptedId = await axiosWithToken(`${functionBaseUrl}/api/encrypt`, {
      method: 'POST',
      data: { id: this.props.uid },
    })
      .catch(() => {
        Swal.fire({
          title: `<p style="font-size:70%;">There was an error integrating your account, please refresh the page and try again.</p>`,
          icon: 'success',
          showConfirmButton: false,
          allowOutsideClick: true,
          customClass: {
            container: 'my-swal'
          },
        })
        this.props.onClose();
      })

    await Swal.fire({
      title: `<p style="font-size:70%;">Successfully Integrated!</p>`,
      icon: 'success',
      showConfirmButton: false,
      allowOutsideClick: true,
      timer: 2000,
      customClass: {
        container: 'my-swal'
      },
    })

    if (this.props.subscriptionType.includes('student')) {
      const deepLink = `golfcoach://student_integrations?clientID=${encryptedId?.data?.id}&userType=student`;
      window.location.href = deepLink;
    } else {
      const deepLink = `golfcoach://coach_integrations?clientID=${encryptedId?.data?.id}&userType=coach`;
      window.location.href = deepLink;
    }

    this.props.onClose();
  }

  render() {
    return (<>
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>
          <h3 style={{ color: "#5C0DEE" }}>Integration</h3>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Typography>
                Would you like to connect your Sportsbox 3DGolf account with Coach 360?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSubmit} color="primary" variant="contained">
            Yes
          </Button>
          <Button
            color="primary"
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>)
  }
}

Integration.propTypes = {
  open: PropTypes.bool,
  closeIntegrationPopUp: PropTypes.func,
  submitQuery: PropTypes.func,
};

export default Integration;
