import Swal from "sweetalert2";
import { listOfSubscriptions } from "../../common/envConfig";
import admin, { axiosWithToken, functionBaseUrl } from "../../common/firebase";

export const getDashboardUser = async () => {
  const userId = admin.auth().currentUser.uid;
  const res = await axiosWithToken(`${functionBaseUrl}/api/users/${userId}`, {
    method: "get",
  });
  const subRes = !res?.data?.subscriptionType?.includes('free') ? await axiosWithToken(
    `${functionBaseUrl}/api/subscriptionByUserId/${userId}`,
    {
      method: "get",
    }
  ) : { data: {} };
  return { ...res.data, sub: subRes.data.data, id: userId };
};

export const getDashboardUserStudents = async ({
  page,
  sort,
  order,
  search,
  subscriptionFilter,
}) => {
  const offset = (page - 1) * 10;
  const userId = admin.auth().currentUser.uid;
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/web/loadStudentsForCoach`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        limit: 10,
        offset,
        sort,
        order,
        search,
        coachId: userId,
        subscriptionFilter,
      },
    }
  );
  return res.data;
};

export const getEnterpriseMasterStudents = async ({
  enterpriseAccountId,
  page,
  sort,
  order,
  search,
  subscriptionFilter,
}) => {
  const offset = (page - 1) * 10;
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/enterprise/students`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        limit: 10,
        offset,
        sort,
        order,
        search,
        masterId: enterpriseAccountId,
        subscriptionFilter,
      },
    }
  );
  return res.data;
};

export const getStudioMasterStudents = async ({
  studioAccountId,
  page,
  sort,
  order,
  search,
  subscriptionFilter,
}) => {
  const offset = (page - 1) * 10;
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/studio/students`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        limit: 10,
        offset,
        sort,
        order,
        search,
        masterId: studioAccountId,
        subscriptionFilter,
      },
    }
  );
  return res.data;
};

export const removeStudent = async (id) => {
  const userId = admin.auth().currentUser.uid;
  await axiosWithToken(`${functionBaseUrl}/api/removeStudent`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      studentId: id,
      coachId: userId,
    },
  });
};

export const removePendingStudent = async (inviteId) => {
  await axiosWithToken(`${functionBaseUrl}/api/invite/${inviteId}`, {
    method: "delete",
  });
};

export const upgradeStudent = async (data) => {
  const res = await axiosWithToken(`${functionBaseUrl}/api/v2/upgradeStudent`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      id: data.studentId,
      coachId: data.coachId,
      coachName: data.coachName,
      subPlan: data.subPlan,
      subPeriod: data.subPeriod,
      autoRenewal: data.autoRenewal,
    },
  });
  return res.data.message;
};

export const enableAutoRenewal = async (data) => {
  await axiosWithToken(`${functionBaseUrl}/api/resumeCreditSub`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      subId: data.subId,
      coachId: data.coachId,
    },
  });
};

export const disableAutoRenewal = async (data) => {
  await axiosWithToken(`${functionBaseUrl}/api/pauseCreditSub`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      subId: data.subId,
      coachId: data.coachId,
    },
  });
};

export const getRevenue = async () => {
  const userId = admin.auth().currentUser?.uid;
  const response = await axiosWithToken(
    `${functionBaseUrl}/api/unpaidStudentsSub`,
    {
      method: "POST",
      data: { userId },
    }
  );
  let total = 0;
  let amount = 0;
  let discount = 10;
  for (let index = 0; index < response.data.data.length; index++) {
    if (response.data.total + index < 29) {
      discount = 10;
    } else if (response.data.total + index < 49) {
      discount = 20;
    } else {
      discount = 30;
    }
    total +=
      response.data.data[index].amount *
      response.data.data[index].coachDetails.period;
    amount +=
      ((response.data.data[index].amount * (100 - discount)) / 100) *
      response.data.data[index].coachDetails.period;
  }

  return { revenue: -(total - amount).toFixed(2), total };
};

export const checkIfUserExists = async (email) => {
  const response = await axiosWithToken(
    `${functionBaseUrl}/api/user-existance-check/${email}`,
    {
      method: "GET",
    }
  );

  return {
    userExists: response?.data?.userExists,
    userId: response?.data?.userId,
    subscriptionType: response?.data?.subscriptionType,
  };
};

export const inviteStudent = async (studentId, grantSub) => {
  const coachId = admin.auth().currentUser.uid;

  const response = await axiosWithToken(`${functionBaseUrl}/api/addStudent`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      studentId,
      coachId,
    },
  });

  if (grantSub) {
    const coachDoc = await axiosWithToken(`${functionBaseUrl}/api/users/${coachId}`, {
      method: "get",
    });
    await upgradeStudent({
      studentId,
      coachId,
      coachName: coachDoc?.data?.firstName + " " + coachDoc?.data?.lastName,
      subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
      subPeriod: 1,
      autoRenewal: false,
    });
  }

  if (response.status === 200) {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Student added successfully",
      timer: 2000,
      customClass: {
        container: "my-swal",
      },
    });
  } else {
    Swal.fire({
      icon: "warning",
      title: response?.data?.message,
      customClass: {
        container: "my-swal",
      },
    });
  }

  return response.data;
};

export const createStudent = async ({
  email,
  firstName,
  lastName,
  gender,
  height,
  dominantHand,
  toGrantSubscription,
}) => {
  const coachId = admin.auth().currentUser.uid;

  const coachRes = await axiosWithToken(
    `${functionBaseUrl}/api/users/${coachId}`,
    {
      method: "get",
    }
  );

  const coachData = coachRes.data;

  const { subPlan, subPeriod, autoRenewal } = (() => {
    if (toGrantSubscription) {
      return {
        subPlan: listOfSubscriptions.STUDENT_LITE_MONTHLY,
        subPeriod: 1,
        autoRenewal: false,
      };
    } else {
      return {
        subPlan: listOfSubscriptions.STUDENT_FREE,
        subPeriod: 1,
        autoRenewal: false,
      };
    }
  })();

  const response = await axiosWithToken(
    `${functionBaseUrl}/api/v3/createStudent`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
        firstName,
        lastName,
        dob: "",
        gender,
        height,
        coachId,
        coachName: coachData.firstName + " " + coachData.lastName,
        dominantHand,
        hipWidth: "",
        subPlan,
        subPeriod,
        autoRenewal,
      },
    }
  );

  return response.data;
};

export const updateUserInfo = async (
  id,
  firstName,
  lastName,
  email,
  height,
  dominantHand,
  gender
) => {
  await axiosWithToken(`${functionBaseUrl}/api/users/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userInfo: {
        firstName,
        lastName,
        userName: `${firstName} ${lastName}`,
        height,
        dominantHand,
        gender,
      },
    },
  });

  await axiosWithToken(`${functionBaseUrl}/api/userEmail`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      id,
      email,
    },
  });
};

export const verifyStaffEnt = async (id) => {
  const res = await axiosWithToken(
    functionBaseUrl + "/api/verifyStaffEnt/" + id,
    {
      method: "POST",
    }
  ).catch((err) => {
    console.log(err);
  });

  return res.data;
};

export const getEnterpriseCoaches = async (enterpriseAccountId) => {
  const response = await axiosWithToken(
    `${functionBaseUrl}/api/usersEnterprise/instructors/${enterpriseAccountId}`,
    {
      method: "get",
    }
  );
  return response.data;
};

export const getStudioCoaches = async (studioAccountId) => {
  const response = await axiosWithToken(
    `${functionBaseUrl}/api/studioCoaches/${studioAccountId}`,
    {
      method: "get",
    }
  );
  return response.data;
};

export const assignCoachToStudent = async (coachId, studentId) => {
  await axiosWithToken(`${functionBaseUrl}/api/addStudent`, {
    data: { coachId, studentId },
    method: "post",
  });
};

export const unassignCoachToStudent = async (coachId, studentId) => {
  await axiosWithToken(`${functionBaseUrl}/api/removeStudent`, {
    data: { coachId, studentId },
    method: "delete",
  });
};

export const getCoachesForEnterpriseMaster = async () => {
  const userId = admin.auth().currentUser.uid;
  const res = await axiosWithToken(
    `${functionBaseUrl}/api/v1/enterprise/coaches`,
    {
      data: { masterId: userId },
      method: "post",
    }
  );

  return res.data;
};
