import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import firebase from '../../common/firebase';
import CloseIcon from "@material-ui/icons/Close";

const NoSubscriptionDialog = ({ activeSub, isStaffEnterprise, isCertified, onboardingCompleted, role, loading, subscriptionType }) => {
  const history = useHistory();
  const [close, setClose] = useState(false);
  loading = false;

  const handleClose = () => {
    setClose(true);
  }

  const handleContinue = () => {
    if (!activeSub) {
      if (subscriptionType.includes('enterprise_master')) {
        if (window.location.href.includes('mobileClient')) {
          history.push('/enterprise/pay?mobileClient');
        } else {
          history.push('/enterprise/pay');
        }
      } else {
        if (window.location.href.includes('mobileClient')) {
          history.push('/account?view=plan?mobileClient');
        } else {
          history.push('/account?view=plan');
        }
      }
    } else if (isStaffEnterprise && !isCertified) {
      window.open('https://sso.teachable.com/secure/1186452/identity/login/password', '_blank').focus();
    } else if (isStaffEnterprise && !onboardingCompleted) {
      window.open('https://meetings.hubspot.com/stephanie-wei/staff-program-onboarding-1-1', '_blank').focus();
    }
  }

  const getTitle = () => {
    if (loading) {
      return <img width="4%" src="images/loading.gif" alt="Loading" />
    } else if (!activeSub) {
      return "You do not have an active subscription"
    } else if (isStaffEnterprise && !isCertified) {
      return "You do not have a valid certification"
    } else if (isStaffEnterprise && !onboardingCompleted) {
      return "You have not completed Onboarding"
    }
  }

  const getBody = () => {
    if (loading) {
      return <img width="4%" src="images/loading.gif" alt="Loading" />
    } else if (!activeSub) {
        if (subscriptionType.includes('enterprise_master')) {
          if (isStaffEnterprise) {
            return "Please press continue to unlock your Staff Enterprise Account access"
          } else {
            return "Please press continue to unlock your Enterprise Account access"
          }
        }
        return "Please choose a plan to access Sportsbox 3D Baseball features"
    } else if (isStaffEnterprise && !isCertified) {
      return <p>Please check your inbox with login details for your Teachable account.<br /><br /> If you already have an account, please visit this <a href="https://sso.teachable.com/secure/1186452/identity/login/password" target="_blank" rel="noopener noreferrer"><b><u>LINK</u></b></a> to complete your Sportsbox Certification process. Once completed, please REFRESH this page.</p>
    } else if (isStaffEnterprise && !onboardingCompleted) {
      return <p>Please complete the 1-on-1 onboarding session by making an appointment <a href="https://meetings.hubspot.com/stephanie-wei/staff-program-onboarding-1-1" target="_blank" rel="noopener noreferrer"><b><u>HERE</u></b></a></p>
    }
  }

  const openState = () => {
    if (loading) {
      return true
    }
    if (close) {
      return false
    }
    if (role === 'admin') {
      return false
    }
    if (!activeSub) {
      return true
    }
    if (isStaffEnterprise === false) {
      return false
    }
    if (isStaffEnterprise && !isCertified) {
      return true
    }
    if (isStaffEnterprise && !onboardingCompleted) {
      return true
    }
  }

  const handleSignOut = () => {
    firebase.auth().signOut().then(() => {
      history.push('/sign-in')
    }).catch((error) => {
      console.log(error)
    });
  }

  return (<>
    <Dialog
      fullWidth
      open={openState()}
      aria-labelledby='form-dialog-title'>
      <DialogTitle id={'form-dialog-title'}>
        <h3>{getTitle()}</h3>
        {getTitle() && (getTitle().toLowerCase().includes('certification') || getTitle().toLowerCase().includes('onboarding')) && <CloseIcon style={{ float: 'right', marginTop: '-10px', marginRight: '-15px' }} onClick={() => {handleClose()}} /> }
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography>
              {getBody()}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSignOut} color="primary">
          Sign Out
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleContinue}
          disabled={loading}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  </>);
};

export default NoSubscriptionDialog;