import React, { useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import s from "./styles.module.css";

export const SelectV2 = ({
  value,
  name,
  onChange,
  options,
  label,
  width = "100%",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(null);

  const handleClick = (event) => {
    const anchorWidth = event.currentTarget.offsetWidth;
    setAnchorEl(event.currentTarget);
    setPopoverWidth(anchorWidth);
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (value) => {
    onChange(name, value);
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={s.container} style={{ width }}>
      <div className={s.selectLabel}>{label}</div>
      <div className={s.selectWrapper} onClick={handleClick}>
        <div className={s.selectValue}>
          <span>{options.find((el) => el.value === value)?.text}</span>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: popoverWidth,
          },
        }}
      >
        <div className={s.options}>
          {options.map((el) => (
            <div
              key={el.value}
              className={s.option}
              onClick={() => handleOptionClick(el.value)}
            >
              {el.text}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};
