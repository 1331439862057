import React, { useState } from "react";
import s from "./styles.module.css";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { ForesightPaymentSuccess } from "./ForesightPaymentSuccess";
import { StripeSubscription } from "../../../../components/stripeSubscription";
import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { listOfSubscriptions } from "../../../../common/envConfig";
import moment from "moment";
import { axiosWithToken, functionBaseUrl } from "../../../../common/firebase";
import Swal from "sweetalert2";

export function ForesightPayment({ fullName, email, userId }) {
  const [successOpen, setSuccessOpen] = useState(false);
  const [subType, setSubType] = useState(
    listOfSubscriptions.FORESIGHT_ANNUALLY
  );

  const createSubscription = async (data) => {
    const input = {
      userId: userId,
      plan: subType.includes("monthly") ? "Foresight Monthly" : "Foresight Annually",
      planType: subType.includes("monthly") ? "Monthly" : "Annually",
      transactionId: data?.id,
      amount: String((data?.amount / 100).toFixed(2)),
      startDate: String(Date.now()),
      endDate: subType.includes("monthly") ? String(moment().add(1, "M").valueOf()) : String(moment().add(1, "y").valueOf()),
      lastUpdated: String(Date.now()),
      autoRenewal: true,
      platform: "Stripe",
      productId: subType.includes("monthly") ? listOfSubscriptions.FORESIGHT_MONTHLY : listOfSubscriptions.FORESIGHT_ANNUALLY,
      userName: fullName,
      userEmail: email,
      promoCode: "",
      isAddOn: true,
    };

    await axiosWithToken(functionBaseUrl + "/api/partnerSub", {
      method: "POST",
      data: input,
    })
      .then(() => {
        setSuccessOpen(true);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again.",
          customClass: {
            container: "my-swal",
          },
        });
      });
  };

  const handleSubTypeChange = () => {
    setSubType((prev) => {
      if (prev === listOfSubscriptions.FORESIGHT_ANNUALLY) {
        return listOfSubscriptions.FORESIGHT_MONTHLY;
      } else {
        return listOfSubscriptions.FORESIGHT_ANNUALLY;
      }
    });
  };

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 34,
      height: 20,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(14px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#6200ea",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#6200ea",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 20 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(Switch);

  return (
    <>
      <div className={s.container}>
        <div className={s.importantBox}>
          <InfoOutlinedIcon />
          <div>
            <div>Important information </div>
            <div>
              1. You will be charged separately from your current subscription,
              and billing date will not the same from your subscription dates.
            </div>
            <div>
              2. To make any changes to your Foresight Integration, you will
              need to manage it via web.
            </div>
          </div>
        </div>
        <div className={s.infoBox}>
          <div className={s.switchBox}>
            <div
              className={
                subType === listOfSubscriptions.FORESIGHT_MONTHLY
                  ? s.bold
                  : ""
              }
            >
              Monthly
            </div>
            <IOSSwitch
              checked={subType === listOfSubscriptions.FORESIGHT_ANNUALLY}
              onChange={handleSubTypeChange}
            />
            <div
              className={
                subType !== listOfSubscriptions.FORESIGHT_MONTHLY
                  ? s.bold
                  : ""
              }
            >
              Annually
            </div>
          </div>
          <img
            style={{ width: "50px", height: "50px" }}
            src={"/images/logos/foresight_logo.svg"}
            alt="Foresight Logo"
          />
          <div className={s.bold}>
            Foresight Integration (
            {subType === listOfSubscriptions.FORESIGHT_MONTHLY
              ? "Monthly"
              : "Annual"}
            )
          </div>
          <div>Auto renewal</div>
          <div
            style={{ borderTop: "1px solid #EAECF0", margin: "10px 0px" }}
          ></div>
          <div
            className={s.bold}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Subtotal</div>
            <div>
              $
              {subType === listOfSubscriptions.FORESIGHT_MONTHLY
                ? "40"
                : "400"}
            </div>
          </div>
        </div>
        <StripeSubscription
          email={email}
          productId={subType}
          handleResult={createSubscription}
          hideCoupon={true}
          fullName={fullName}
          showDetails={false}
        />
      </div>
      <ForesightPaymentSuccess
        isOpen={successOpen}
        onClose={() => window.location.reload()}
      />
    </>
  );
}
