import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import s from "./styles.module.css";
import { Checkbox } from "@material-ui/core";

export const StudentCoaches = ({
  value,
  onCoachClick,
  enterpriseCoaches,
  studentCoaches,
  studentId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (coachId) => {
    if (studentCoaches.length === 1 && studentCoaches[0] === coachId) return;

    onCoachClick(studentId, coachId, studentCoaches);
  };

  const selectedCheck = (option) => {
    return studentCoaches.indexOf(option) > -1;
  };

  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={s.container} ref={containerRef}>
      <div className={s.selectWrapper} onClick={() => setIsOpen(!isOpen)}>
        <div className={s.selectValue}>
          <span>{value}</span>
          <KeyboardArrowDownIcon />
        </div>
        <div
          className={isOpen ? `${s.options} ${s.optionsVisible}` : s.options}
        >
          {enterpriseCoaches.map((el) => (
            <div
              key={el.value}
              className={s.option}
              onClick={() => handleOptionClick(el.value)}
            >
              <Checkbox
                style={{ padding: 0 }}
                checked={selectedCheck(el.value)}
                disabled={
                  studentCoaches.length === 1 && selectedCheck(el.value)
                }
              />
              {el.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
