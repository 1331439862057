import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import s from "./styles.module.css";
import { InputV2 } from "../InputV2/InputV2";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import { CircularProgress } from "@material-ui/core";

export const SelectV3WithServerSearch = ({
  values,
  name,
  onChange,
  options,
  emptyValue,
  showSelectedNumberOnValue,
  optionsLabel,
  searchRequest,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [optionsState, setOptionsState] = useState(options);
  const [loading, setLoading] = useState(false); // New state to handle loading

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  useEffect(() => {
    let debounceTimer;
    if (searchValue.length) {
      const debounceSearch = async () => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(async () => {
          setLoading(true);
          const resp = await searchRequest(searchValue);
          setOptionsState(resp);
          setLoading(false);
        }, 1000);
      };
      debounceSearch();
    } else {
      setOptionsState(options);
    }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchValue]);

  const handleClick = (event) => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 300);
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClick = (value) => {
    onChange(name, value);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setSearchValue("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const renderLabel = () => {
    const firstHit = optionsState.find((el) => el.value === values[0])?.name;
    if (firstHit) {
      return firstHit;
    } else {
      return options.find((el) => el.value === values[0])?.name;
    }
  };

  return (
    <div className={s.container}>
      <div
        style={{ fontSize: "14px" }}
        className={`${s.selectWrapper} ${
          values.length ? s.selectActive : s.selectInActive
        } ${isAnimating ? s.animateButton : ""}`}
        onClick={handleClick}
      >
        <div className={s.selectValue}>
          <span>
            {values.length ? (
              showSelectedNumberOnValue ? (
                <>
                  <span
                    style={{
                      backgroundColor: "#E2D2FC",
                      padding: "2px 8px",
                      borderRadius: "14px",
                      marginRight: "5px",
                    }}
                  >
                    {values.length}
                  </span>
                  {emptyValue}
                </>
              ) : (
                `${renderLabel()} ${
                  values.length > 1 ? `+${values.length - 1}` : ""
                }`
              )
            ) : (
              emptyValue
            )}
          </span>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            minWidth: "200px",
            marginTop: "10px",
            borderRadius: "10px",
          },
        }}
      >
        <div className={s.options}>
          <div className={s.optionsLabel}>{optionsLabel}</div>

          <div className={s.searchContainer}>
            <InputV2
              onChange={(name, value) => setSearchValue(value)}
              name="search"
              value={searchValue}
              placeholder="Search"
              disabled={false}
            />
          </div>

          {loading ? (
            <div className={s.loadingIcon}>
              <CircularProgress
                style={{ display: loading ? "block" : "none" }}
              />
            </div>
          ) : (
            optionsState.map((el) => (
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
                className={s.option}
                key={el.value}
                onClick={() => handleOptionClick(el.value)}
              >
                {values.length && values.includes(el.value) ? (
                  <CheckBoxOutlinedIcon style={{ color: "#4009A5" }} />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon
                    style={{ color: "#4009A5" }}
                  />
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>{el.name}</div>
                  <div style={{ fontSize: "smaller", color: "gray" }}>
                    {el.email}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </Popover>
    </div>
  );
};
