import React, { useEffect, useState } from "react";

import s from "./style.module.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Popover, Switch } from "@material-ui/core";
import moment from "moment";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";

export const MainPageInvitations = ({
  tableOptions,
  onPagination,
  data,
  total,
  onResendInvite,
  onRemoveInvite,
}) => {
  const [popover, setPopover] = useState({ id: "", anchorEl: null });

  const totalToPages = (() => {
    const calc = total / 10;
    const fin = calc < 1 ? 1 : Math.ceil(calc);

    const pagesArr = [];
    for (let i = 1; i <= fin; i++) {
      pagesArr.push(i);
    }

    return pagesArr;
  })();

  const handlePopoverOpen = (event, id) => {
    setPopover({ anchorEl: event.currentTarget, id: id });
  };

  const useStyles = makeStyles((theme) => ({
    transparentPopover: {
      borderRadius: "10px", // Set the background to transparent
      // Add more styles if needed
    },
  }));

  const classes = useStyles();

  const handleNextPreviousPagination = (type) => {
    if (type === "next") {
      if (tableOptions.page < totalToPages.length) {
        onPagination(tableOptions.page + 1);
      }
    } else {
      if (tableOptions.page > 1) {
        onPagination(tableOptions.page - 1);
      }
    }
  };

  return (
    <div>
      <div className={s.header}>
        <PeopleAltOutlinedIcon style={{ color: "#4009A5" }} />
        <div>Pending Invitations</div>
      </div>
      <div style={{ marginLeft: "30px" }} className={s["table-container"]}>
        <table className={s["styled-table"]}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Invited</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((el) => {
                return (
                  <tr key={el.id}>
                    <td>{el.inviteeName}</td>
                    <td>{el.invitee}</td>
                    <td>
                      <div className={s.tableStatusPending}>{el.status}</div>
                    </td>
                    <td>
                      {Math.floor(
                        (Date.now() - +el.createdAt) / (1000 * 60 * 60 * 24)
                      )}{" "}
                      days ago
                    </td>
                    <td>
                      <div onClick={(e) => handlePopoverOpen(e, el.id)}>
                        <MoreVertIcon style={{ cursor: "pointer" }} />
                      </div>

                      <Popover
                        PaperProps={{
                          className: classes.transparentPopover,
                        }}
                        id={`${el.id}-popover`}
                        open={el.id === popover.id}
                        anchorEl={popover.anchorEl}
                        onClose={() => setPopover("")}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <div className={s.popoverItemsContainer}>
                          {el?.status !== "approved" && <div
                            className={s.popoverItem}
                            onClick={() => onResendInvite(el.id)}
                          >
                            <SendIcon />
                            <div>Resend invitation</div>
                          </div>}
                          <div
                            style={{ color: "#B42318" }}
                            className={s.popoverItem}
                            onClick={() => {
                              onRemoveInvite(el.id);
                            }}
                          >
                            <RemoveIcon />
                            <div>Remove student</div>
                          </div>
                        </div>
                      </Popover>
                    </td>
                  </tr>
                );
              })}
          </tbody>
          {/* <tfoot>
          <tr>
            <td colSpan="9">
              <div className={s.pagination}>
                <button
                  onClick={() => handleNextPreviousPagination("previous")}
                  className={s["pagination-button"]}
                  disabled={tableOptions.page === 1}
                >
                  <ArrowBackIcon /> Previous
                </button>
                <div className={s["pagination-items"]}>
                  {totalToPages.map((el) => {
                    return (
                      <div
                        className={
                          tableOptions.page === el ? s.activePage : s.page
                        }
                        onClick={() => onPagination(el)}
                      >
                        {el}
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={() => handleNextPreviousPagination("next")}
                  className={s["pagination-button"]}
                  disabled={tableOptions.page === totalToPages.length}
                >
                  Next <ArrowForwardIcon />
                </button>
              </div>
            </td>
          </tr>
        </tfoot> */}
        </table>
      </div>
    </div>
  );
};
