import React, { useState } from "react";
import s from "./styles.module.css";
import { InputV2 } from "../../../../components/redesign-components/InputV2/InputV2";
import { ButtonV2 } from "../../../../components/redesign-components/ButtonV2/ButtonV2";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DoneIcon from "@material-ui/icons/Done";
import {
  listOfSubscriptions,
  plansEnvConfig,
} from "../../../../common/envConfig";
import PlansPaymentComponent from "../../../plans/plansPayment";
import { Switch } from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import { functionBaseUrl } from "../../../../common/firebase";
import { IOSSwitch } from "../../../../components/redesign-components/IOSSwitch/IOSSwitch";

const PeriodSwitch = ({ isMonthly, onClick }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ fontWeight: "600" }}>Monthly</div>
      <Switch checked={!isMonthly} onClick={onClick} />
      <div style={{ fontWeight: "600" }}>Annually</div>
    </div>
  );
};

const calculatePriceForCard = (
  monthlyPrice,
  annualPrice,
  isMonthlyActive,
  isAddonActive
) => {
  if (isMonthlyActive) {
    if (isAddonActive) return `$${+monthlyPrice + 40}/mo`;
    else return `$${monthlyPrice}/mo`;
  } else {
    if (isAddonActive) return `$${+annualPrice + 400}/year`;
    else return `$${annualPrice}/year`;
  }
};

const PlanCard = ({
  textList,
  type,
  header,
  subText,
  plan,
  purchaseble = false,
  currentPlan,
  onGetStarted,
  addonDetails = { show: false, state: false, onChange: () => "" },
}) => {
  return (
    <div className={s.container}>
      <div className={s.paddingContainer}>
        <div className={s.type}>{type}</div>
        <div className={s.header}>{header}</div>
        <div className={s.subtext}>{subText}</div>
        {addonDetails.show && !plan.includes(currentPlan) && (
          <div className={s.addonContainer}>
            <div className={s.addonTag}>Add-on</div>
            <div className={s.addonTextContainer}>
              <div>Foresight Integration for $400</div>
              <IOSSwitch
                checked={addonDetails.state}
                onChange={addonDetails.onChange}
              />
            </div>
          </div>
        )}

        <div className={s.subItemContainer}>
          {textList.map((el) => {
            return (
              <div className={s.subItem}>
                <div className={s.subItemIcon}>
                  <DoneIcon fontSize="small" />
                </div>
                <div>{el}</div>
              </div>
            );
          })}
        </div>
      </div>

      {plan.includes(currentPlan) ? (
        <div className={s.grayBg}>
          <div className={s.greenText}>
            <DoneIcon />
            <div>Current plan</div>
          </div>
        </div>
      ) : purchaseble ? (
        <div className={s.grayBg}>
          <ButtonV2 onClick={onGetStarted} text="Get started" color="purple" />
        </div>
      ) : (
        <a href="mailto:sales@sportsbox.ai" className={s.grayBg}>
          <ButtonV2 text="Let’s chat" />
        </a>
      )}
    </div>
  );
};

const EnterprisePlans = ({ currentPlan, onGetStarted }) => {
  return (
    <div className={s.flexContainer}>
      <PlanCard
        type="Studio"
        header="Coming soon!"
        subText="Perfect for indoor setups"
        textList={[
          "Advanced custom fields",
          "Audit log and data history",
          "Unlimited individual users",
          "Unlimited individual data",
          "Personalised + priotity service",
        ]}
        plan={["studio"]}
        currentPlan={currentPlan}
      />
      <PlanCard
        type="Enterprise"
        header="Contact sales"
        subText="Help your team level up with 3D"
        textList={[
          "Advanced custom fields",
          "Audit log and data history",
          "Unlimited individual users",
          "Unlimited individual data",
          "Personalised + priotity service",
        ]}
        plan={[
          listOfSubscriptions.ENTERPRISE_COACH,
          listOfSubscriptions.ENTERPRISE_MASTER,
        ]}
        currentPlan={currentPlan}
      />
    </div>
  );
};

const StudioPlans = ({ currentPlan, onGetStarted }) => {
  return (
    <div className={s.flexContainer}>
      <PlanCard
        type="Studio"
        header="Coming soon!"
        subText="Perfect for indoor setups"
        textList={[
          "Advanced custom fields",
          "Audit log and data history",
          "Unlimited individual users",
          "Unlimited individual data",
          "Personalised + priotity service",
        ]}
        plan={[
          listOfSubscriptions.STUDIO_MASTER,
          listOfSubscriptions.STUDIO_COACH,
        ]}
        currentPlan={currentPlan}
      />
    </div>
  );
};

const CoachPlans = ({ currentPlan, onGetStarted }) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [isAddonActive, setIsAddonActive] = useState({
    show3dPro: false,
  });

  const handleAddonChange = (type) => {
    switch (type) {
      case "3dPro":
        setIsAddonActive((prev) => ({
          ...prev,
          show3dPro: !prev.show3dPro,
        }));
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className={s.switchContainer}>
        <PeriodSwitch
          isMonthly={isMonthly}
          onClick={() => setIsMonthly((prev) => !prev)}
        />
      </div>

      <div className={s.flexContainer}>
        <PlanCard
          type="3D Pro"
          header={calculatePriceForCard(
            plansEnvConfig[listOfSubscriptions.PREMIUM_MONTHLY],
            plansEnvConfig[listOfSubscriptions.PREMIUM_ANNUALLY],
            isMonthly,
            isAddonActive.show3dPro
          )}
          subText="Level up your lessons with 3D"
          textList={[
            "Unlimited students",
            "Unlimited swings",
            "All 2D and 3D analysis features",
            "All measurements",
            "Sportsbox drill library (50+ drills)",
            "2 devices per user",
          ]}
          plan={
            isMonthly
              ? [
                  listOfSubscriptions.LITE_MONTHLY,
                  listOfSubscriptions.PREMIUM,
                  listOfSubscriptions.PREMIUM_MONTHLY,
                ]
              : [
                  listOfSubscriptions.LITE_ANNUALLY,
                  listOfSubscriptions.PREMIUM_ANNUALLY,
                ]
          }
          currentPlan={currentPlan}
          purchaseble
          onGetStarted={() =>
            onGetStarted(
              isMonthly
                ? listOfSubscriptions.PREMIUM_MONTHLY
                : listOfSubscriptions.PREMIUM_ANNUALLY,
              isAddonActive.show3dPro
            )
          }
          addonDetails={{
            show: true,
            state: isAddonActive.show3dPro,
            onChange: () => handleAddonChange("3dPro"),
          }}
        />
        <PlanCard
          type="Studio"
          header="Coming soon!"
          subText="Perfect for indoor setups"
          textList={[
            "Advanced custom fields",
            "Audit log and data history",
            "Unlimited individual users",
            "Unlimited individual data",
            "Personalised + priotity service",
          ]}
          plan={["studio"]}
          currentPlan={currentPlan}
        />
        <PlanCard
          type="Enterprise"
          header="Contact sales"
          subText="Help your team level up with 3D"
          textList={[
            "Team management",
            "Personal account management & onboarding",
            "1TB cloud storage per user (100,000 swings)",
            "Unlimited individual data",
            "Personalised + priotity service",
          ]}
          plan={["enterprise"]}
          currentPlan={currentPlan}
        />
      </div>
    </div>
  );
};

const StudentFreePlans = ({ currentPlan, onGetStarted }) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [isAddonActive, setIsAddonActive] = useState({
    show3dPlayer: false,
    show3dPro: false,
  });

  const handleAddonChange = (type) => {
    switch (type) {
      case "3dPlayer":
        setIsAddonActive((prev) => ({
          ...prev,
          show3dPlayer: !prev.show3dPlayer,
        }));
        break;
      case "3dPro":
        setIsAddonActive((prev) => ({
          ...prev,
          show3dPro: !prev.show3dPro,
        }));
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {/*<div className={s.switchContainer}>
        <PeriodSwitch
          isMonthly={isMonthly}
          onClick={() => setIsMonthly((prev) => !prev)}
        />
      </div>*/}
      <div className={s.flexContainer}>
        <PlanCard
          type="Basic"
          header="Free"
          subText="Get started with the basics"
          textList={[
            "5 swings per month",
            "1 goal recommended by Sportsbox",
            "Limited measurements related to your goal",
            "Review and analyze swings captured by a coach",
          ]}
          plan={[listOfSubscriptions.STUDENT_FREE]}
          currentPlan={currentPlan}
        />
        {/*<PlanCard
          type="3D Player"
          header={calculatePriceForCard(
            plansEnvConfig[listOfSubscriptions.STUDENT_LITE_MONTHLY],
            plansEnvConfig[listOfSubscriptions.STUDENT_LITE_ANNUALLY],
            isMonthly,
            isAddonActive.show3dPlayer
          )}
          subText="Level up your lessons with 3D"
          textList={[
            "Unlimited swings",
            "Unlimited goals and customizable goals",
            "Basic tracker and indicator measurements",
            "Auto-swing detection",
            "2D and 3D comparison",
            "50+ practice drills with assessments",
          ]}
          plan={
            isMonthly
              ? [
                  listOfSubscriptions.STUDENT_LITE,

                  listOfSubscriptions.STUDENT_LITE_MONTHLY,
                ]
              : [listOfSubscriptions.STUDENT_LITE_ANNUALLY]
          }
          currentPlan={currentPlan}
          purchaseble
          onGetStarted={() =>
            onGetStarted(
              isMonthly
                ? listOfSubscriptions.STUDENT_LITE_MONTHLY
                : listOfSubscriptions.STUDENT_LITE_ANNUALLY,
              isAddonActive.show3dPlayer
            )
          }
          addonDetails={{
            show: true,
            state: isAddonActive.show3dPlayer,
            onChange: () => handleAddonChange("3dPlayer"),
          }}
        />
        <PlanCard
          type="3D Pro"
          header={calculatePriceForCard(
            plansEnvConfig[listOfSubscriptions.PREMIUM_MONTHLY],
            plansEnvConfig[listOfSubscriptions.PREMIUM_ANNUALLY],
            isMonthly,
            isAddonActive.show3dPro
          )}
          subText="Level up your lessons with 3D"
          textList={[
            "Unlimited students",
            "Unlimited swings",
            "All 2D and 3D analysis features",
            "All measurements",
            "Sportsbox drill library (50+ drills)",
            "2 devices per user",
          ]}
          plan={
            isMonthly
              ? [
                  listOfSubscriptions.LITE_MONTHLY,
                  listOfSubscriptions.PREMIUM,
                  listOfSubscriptions.PREMIUM_MONTHLY,
                ]
              : [
                  listOfSubscriptions.LITE_ANNUALLY,
                  listOfSubscriptions.PREMIUM_ANNUALLY,
                ]
          }
          currentPlan={currentPlan}
          purchaseble
          onGetStarted={() =>
            onGetStarted(
              isMonthly
                ? listOfSubscriptions.PREMIUM_MONTHLY
                : listOfSubscriptions.PREMIUM_ANNUALLY,
              isAddonActive.show3dPro
            )
          }
          addonDetails={{
            show: true,
            state: isAddonActive.show3dPro,
            onChange: () => handleAddonChange("3dPro"),
          }}
        />*/}
      </div>
    </div>
  );
};

const StudentPlans = ({ currentPlan, onGetStarted }) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [isAddonActive, setIsAddonActive] = useState({
    show3dPlayer: false,
    show3dPro: false,
  });

  const handleAddonChange = (type) => {
    switch (type) {
      case "3dPlayer":
        setIsAddonActive((prev) => ({
          ...prev,
          show3dPlayer: !prev.show3dPlayer,
        }));
        break;
      case "3dPro":
        setIsAddonActive((prev) => ({
          ...prev,
          show3dPro: !prev.show3dPro,
        }));
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className={s.switchContainer}>
        <PeriodSwitch
          isMonthly={isMonthly}
          onClick={() => setIsMonthly((prev) => !prev)}
        />
      </div>
      <div className={s.flexContainer}>
        <PlanCard
          type="3D Player"
          header={calculatePriceForCard(
            plansEnvConfig[listOfSubscriptions.STUDENT_LITE_MONTHLY],
            plansEnvConfig[listOfSubscriptions.STUDENT_LITE_ANNUALLY],
            isMonthly,
            isAddonActive.show3dPlayer
          )}
          subText="Level up your lessons with 3D"
          textList={[
            "Unlimited swings",
            "Unlimited goals and customizable goals",
            "Basic tracker and indicator measurements",
            "Auto-swing detection",
            "2D and 3D comparison",
            "50+ practice drills with assessments",
          ]}
          plan={
            isMonthly
              ? [
                  listOfSubscriptions.STUDENT_LITE,
                  listOfSubscriptions.STUDENT_LITE_MONTHLY,
                ]
              : [listOfSubscriptions.STUDENT_LITE_ANNUALLY]
          }
          currentPlan={currentPlan}
          purchaseble
          onGetStarted={() =>
            onGetStarted(
              isMonthly
                ? listOfSubscriptions.STUDENT_LITE_MONTHLY
                : listOfSubscriptions.STUDENT_LITE_ANNUALLY,
              isAddonActive.show3dPlayer
            )
          }
          addonDetails={{
            show: true,
            state: isAddonActive.show3dPlayer,
            onChange: () => handleAddonChange("3dPlayer"),
          }}
        />
        <PlanCard
          type="3D Pro"
          header={calculatePriceForCard(
            plansEnvConfig[listOfSubscriptions.PREMIUM_MONTHLY],
            plansEnvConfig[listOfSubscriptions.PREMIUM_ANNUALLY],
            isMonthly,
            isAddonActive.show3dPro
          )}
          subText="Level up your lessons with 3D"
          textList={[
            "Unlimited students",
            "Unlimited swings",
            "All 2D and 3D analysis features",
            "All measurements",
            "Sportsbox drill library (50+ drills)",
            "2 devices per user",
          ]}
          plan={
            isMonthly
              ? [
                  listOfSubscriptions.LITE_MONTHLY,
                  listOfSubscriptions.PREMIUM,
                  listOfSubscriptions.PREMIUM_MONTHLY,
                ]
              : [
                  listOfSubscriptions.LITE_ANNUALLY,
                  listOfSubscriptions.PREMIUM_ANNUALLY,
                ]
          }
          currentPlan={currentPlan}
          purchaseble
          onGetStarted={() =>
            onGetStarted(
              isMonthly
                ? listOfSubscriptions.PREMIUM_MONTHLY
                : listOfSubscriptions.PREMIUM_ANNUALLY,
              isAddonActive.show3dPro
            )
          }
          addonDetails={{
            show: true,
            state: isAddonActive.show3dPro,
            onChange: () => handleAddonChange("3dPro"),
          }}
        />
        <PlanCard
          type="Studio"
          header="Coming soon!"
          subText="Perfect for indoor setups"
          textList={[
            "Advanced custom fields",
            "Audit log and data history",
            "Unlimited individual users",
            "Unlimited individual data",
            "Personalised + priotity service",
          ]}
          plan={["studio"]}
          currentPlan={currentPlan}
        />
      </div>
    </div>
  );
};

export const AccountDetailsPlanChoice = ({
  onViewChange,
  type,
  currentPlan,
  email,
  userId,
  subscriptionData,
  fullName,
  onSubscriptionPurchase,
}) => {
  const [purchaseView, setPurchaseView] = useState({ show: false, plan: "", addOn: false });

  const typeSwitch = () => {
    switch (type = "Free") {
      case "Enterprise":
        return (
          <EnterprisePlans
            currentPlan={
              subscriptionData?.endDate > Date.now() ? currentPlan : ""
            }
            onGetStarted={handleGetStartedClick}
          />
        );
      case "Studio":
        return (
          <StudioPlans
            currentPlan={
              subscriptionData?.endDate > Date.now() ? currentPlan : ""
            }
            onGetStarted={handleGetStartedClick}
          />
        );
      case "Student":
        return (
          <StudentPlans
            currentPlan={
              subscriptionData?.endDate < Date.now() ||
              subscriptionData?.platform !== "Stripe"
                ? ""
                : currentPlan
            }
            onGetStarted={handleGetStartedClick}
          />
        );
      case "Free":
        return (
          <StudentFreePlans
            currentPlan={currentPlan}
            onGetStarted={handleGetStartedClick}
          />
        );
      default:
        return (
          <CoachPlans
            currentPlan={
              subscriptionData?.endDate < Date.now() ||
              subscriptionData?.platform !== "Stripe"
                ? ""
                : currentPlan
            }
            onGetStarted={handleGetStartedClick}
          />
        );
    }
  };

  const handleGetStartedClick = async (selectedPlan, addOn) => {
    let response = "";
    if (
      subscriptionData &&
      (subscriptionData.autoRenewal || subscriptionData.endDate > Date.now())
    ) {
      response = await Swal.fire({
        title: '<p style="font-size:70%;">Active Subscription</p>',
        html:
          "<p>It looks like you have an active subscription, if you wish to proceed, your current subscription will be forfeited. Would you like to continue?</p>" +
          '<p style="font-size:80%;"><br/><br/><b>APPLE or GOOGLE SUBSCRIBERS:</b> Please <a target="_blank" rel="noopener noreferrer" href="https://help.sportsbox.ai/how-do-i-cancel-my-sportsbox-3dgolf-subscription">cancel your current Apple or Google subscription</a> immediately or you will continue to be charged. It is your responsibility to cancel your current plan.</p>',
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonColor: "red",
        customClass: {
          container: "my-swal",
        },
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await axios(functionBaseUrl + "/api/cancelSubHelper", {
              method: "POST",
              data: { email: email },
            }).catch((err) => {
              console.log(err);
            });
          } else {
            return null;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (response === null) {
      return;
    }

    setPurchaseView({ show: true, selectedPlan, addOn });
  };

  return (
    <>
      {purchaseView.show ? (
        <PlansPaymentComponent
          plan={purchaseView.selectedPlan}
          bootcampUser={false}
          email={email}
          uid={userId}
          finish={() => window.location.reload()}
          back={() => onSubscriptionPurchase(false)}
          subData={subscriptionData}
          fullName={fullName}
          addOn={purchaseView.addOn}
        />
      ) : (
        <div className={s.shadowContainer}>
          <div onClick={onViewChange} className={s.iconContainer}>
            <ArrowBackIcon />
            <div>Back</div>
          </div>
          {typeSwitch()}
        </div>
      )}
    </>
  );
};
