import { Avatar, CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import s from "./style.module.css";
import InfiniteScroll from "react-infinite-scroll-component";

const ActivityItem = ({ data }) => {
  function generateNotificationBody(
    notificationType,
    textContent,
    contextDetails
  ) {
    let textContentModified = textContent;
    if (textContentModified.length > 100) {
      textContentModified = textContentModified.substring(0, 97) + "...";
    }

    switch (notificationType) {
      case "feedPost":
        if (contextDetails && contextDetails.postType) {
          switch (contextDetails.postType) {
            case "note":
              return "posted a note: " + textContentModified;
            case "swing":
              if (!textContentModified.isEmpty) {
                return "posted a swing: " + textContentModified;
              } else {
                return "posted a swing";
              }
          }
        }
        return "created a new post";
      case "assignedPost":
        return "assigned you a post";
      case "completedPracticeGuide":
        return "completed a practice guide: " + textContentModified;
      case "newLike":
        return "liked your post: " + textContentModified;
      case "newComment":
        return "replied on your post: " + textContentModified;
      case "inboxMessage":
        if (contextDetails && contextDetails.inboxMessageType) {
          switch (contextDetails.inboxMessageType) {
            case "text":
              return "messaged you: " + textContentModified;
            case "audio":
              return "sent you an audio message";
            case "image":
              return "sent you an image";
            case "video":
              return "sent you a video";
            case "combined":
              return "sent you a message";
          }
        }
        return "messaged you";
      case "assignedWatchlist":
        return "assigned you a goal";
      case "assignedPracticeGuide":
        return "assigned you a practice guide";
      case "coachInvite":
        if (contextDetails && contextDetails.inviteStatus) {
          switch (contextDetails.inviteStatus) {
            case "declined":
              return "invited you to be his (her) student, but you declined the invite.";
            case "accepted":
              return "invited you to be his (her) student and you accepted the invite.";
          }
        } else {
          if (
            contextDetails &&
            contextDetails.invite &&
            contextDetails.invite.status === "expired"
          ) {
            return "invited you to be his (her) student, but the invitation has expired.";
          } else {
            return "is inviting you to be his (her) student";
          }
        }
      case "newStudent":
        return "is now your student";
      case "newCoach":
        return "is now your coach";
      case "createdSession":
        return "created a session for you";
      case "declinedInvite":
        return "declined your invitation";
      case "unknown":
        return "sent a notification that is not supported by the current version of the application";
      default:
        return "";
    }
  }

  return (
    <div className={s.activityContainer}>
      <Avatar src={data.triggeredByUser.picture} />

      <div className={s.activityInnerContainer}>
        <div>
          {data.triggeredByUser.firstName +
            " " +
            data.triggeredByUser.lastName +
            " " +
            generateNotificationBody(
              data.notificationType,
              data.textContent,
              data.contextDetails
            )}
        </div>
        <div className={s.activitySubText}>
          {Math.floor((Date.now() - data.createdAt) / (1000 * 60 * 60 * 24))}{" "}
          days ago • {data.notificationCategory}
        </div>
      </div>
    </div>
  );
};

export const ActivityList = ({ data, onScroll, total }) => {
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className={s.header}>Latest Activity</div>
      <div id="scrollableDiv" className={s.scrollContainer}>
        <InfiniteScroll
          dataLength={data.length}
          next={onScroll}
          hasMore={data.length < total}
          loader={<div>Loading...</div>}
          endMessage={<span />}
          scrollableTarget="scrollableDiv"
        >
          {data.map((el) => {
            return <ActivityItem key={el.id} data={el} />;
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};
