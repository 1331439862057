import React from "react";
import s from "./styles.module.css";
import {
  Dialog,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { listOfSubscriptions } from "../../../../../common/envConfig";
import { DashboardButton } from "../../DashboardButton";

export const UpgradeStudentDialog = ({
  open,
  onClose,
  form,
  onFormChange,
  onSubmit,
  studentDetails,
}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle id="simple-dialog-title">Renew subscription</DialogTitle>
      <div className={s.renewDialogContent}>
        <TextField
          fullWidth
          disabled
          label="Name"
          name="name"
          margin="dense"
          value={studentDetails?.name}
          variant="outlined"
        />
        <TextField
          fullWidth
          disabled
          label="Email"
          name="email"
          margin="dense"
          value={studentDetails?.email}
          variant="outlined"
        />
        <FormControl variant="outlined" fullWidth margin="dense">
          <InputLabel id="subPlan-select">Account Type*</InputLabel>
          <Select
            id="subPlan"
            label="Account Type*"
            labelId="subPlan-select"
            name="subPlan"
            value={form.subPlan}
            onChange={(e) => onFormChange("subPlan", e.target.value)}
          >
            <MenuItem value={listOfSubscriptions.STUDENT_LITE_MONTHLY}>
              3D Player Monthly
            </MenuItem>
            <MenuItem value={listOfSubscriptions.STUDENT_LITE_ANNUALLY}>
              3D Player Annually
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Subscription Period*"
          name="subPeriod"
          margin="dense"
          onChange={(e) => onFormChange("subPeriod", e.target.value)}
          value={form.subPeriod}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {form.subPlan.includes("annually") ? "years" : "months"}
              </InputAdornment>
            ),
            inputProps: { min: 0 },
          }}
          type="number"
          variant="outlined"
        />
        {form.subPeriod !== "" && form.subPeriod <= 0 && (
          <Typography style={{ color: "red" }}>
            Subscription Period has to be greater than 0
          </Typography>
        )}

        <FormControl variant="outlined" fullWidth margin="dense">
          <InputLabel id="autoRenewal-select">Auto Renewal*</InputLabel>
          <Select
            id="autoRenewal"
            label="Auto Renewal*"
            labelId="autoRenewal-select"
            name="autoRenewal"
            value={form.autoRenewal}
            onChange={(e) => onFormChange("autoRenewal", e.target.value)}
          >
            <MenuItem value={true}>On</MenuItem>
            <MenuItem value={false}>Off</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={s.renewDialogButtons}>
        <DashboardButton onClick={onClose} color="gray" text="Cancel" fit />
        <DashboardButton
          onClick={onSubmit}
          disabled={
            form.subPlan === "" ||
            (form.subPlan.length > 0 && form.subPeriod === 0) ||
            (form.subPlan.length > 0 && form.subPeriod <= 0)
          }
          color="blue"
          text="Submit"
          fit
        />
      </div>
    </Dialog>
  );
};
