import React, { useEffect } from "react";
import s from "./styles.module.css";
import { Dialog, DialogTitle, TextField } from "@material-ui/core";
import { DashboardButton } from "../../DashboardButton";
import { DashboardSelect } from "../../dashboard-select/DashboardSelect";

export const EditStudentDialog = ({
  open,
  onClose,
  form,
  onFormChange,
  onSubmit,
  studentId,
  status,
}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle id="simple-dialog-title">Edit student</DialogTitle>
      <div className={s.renewDialogContent}>
        <TextField
          fullWidth
          label="First Name"
          name="firstName"
          margin="dense"
          value={form.firstName}
          onChange={(e) => onFormChange("firstName", e.target.value)}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Last Name"
          name="lastName"
          margin="dense"
          value={form.lastName}
          onChange={(e) => onFormChange("lastName", e.target.value)}
          variant="outlined"
        />
        <TextField
          fullWidth
          disabled={status}
          label="Email"
          name="email"
          margin="dense"
          value={form.email}
          onChange={(e) => onFormChange("email", e.target.value)}
          variant="outlined"
        />
        <DashboardSelect
          label="Height"
          name="height"
          value={form.height}
          onChange={(field, value) => onFormChange(field, value)}
          options={[
            { text: "", value: null },
            { text: "4'0\"", value: "4'0\"" },
            { text: "4'1\"", value: "4'1\"" },
            { text: "4'2\"", value: "4'2\"" },
            { text: "4'3\"", value: "4'3\"" },
            { text: "4'4\"", value: "4'4\"" },
            { text: "4'5\"", value: "4'5\"" },
            { text: "4'6\"", value: "4'6\"" },
            { text: "4'7\"", value: "4'7\"" },
            { text: "4'8\"", value: "4'8\"" },
            { text: "4'9\"", value: "4'9\"" },
            { text: "4'10\"", value: "4'10\"" },
            { text: "4'11\"", value: "4'11\"" },
            { text: "5'0\"", value: "5'0\"" },
            { text: "5'1\"", value: "5'1\"" },
            { text: "5'2\"", value: "5'2\"" },
            { text: "5'3\"", value: "5'3\"" },
            { text: "5'4\"", value: "5'4\"" },
            { text: "5'5\"", value: "5'5\"" },
            { text: "5'6\"", value: "5'6\"" },
            { text: "5'7\"", value: "5'7\"" },
            { text: "5'8\"", value: "5'8\"" },
            { text: "5'9\"", value: "5'9\"" },
            { text: "5'10\"", value: "5'10\"" },
            { text: "5'11\"", value: "5'11\"" },
            { text: "6'0\"", value: "6'0\"" },
            { text: "6'1\"", value: "6'1\"" },
            { text: "6'2\"", value: "6'2\"" },
            { text: "6'3\"", value: "6'3\"" },
            { text: "6'4\"", value: "6'4\"" },
            { text: "6'5\"", value: "6'5\"" },
            { text: "6'6\"", value: "6'6\"" },
            { text: "6'7\"", value: "6'7\"" },
            { text: "6'8\"", value: "6'8\"" },
            { text: "6'9\"", value: "6'9\"" },
            { text: "6'10\"", value: "6'10\"" },
            { text: "6'11\"", value: "6'11\"" },
            { text: "7'0\"", value: "7'0\"" },
          ]}
        />
        <DashboardSelect
          label="Gender"
          name="gender"
          value={form.gender}
          onChange={(field, value) => onFormChange(field, value)}
          options={[
            { text: "", value: null },
            { text: "Male", value: "Male" },
            { text: "Female", value: "Female" },
            { text: "Prefer not to Answer", value: "Prefer not to Answer" },
          ]}
        />
        <DashboardSelect
          label="Dominant Hand"
          name="dominantHand"
          value={form.dominantHand}
          onChange={(field, value) => onFormChange(field, value)}
          options={[
            { text: "", value: null },
            { text: "Right", value: "Right" },
            { text: "Left", value: "Left" },
          ]}
        />
      </div>
      <div className={s.renewDialogButtons}>
        <DashboardButton onClick={onClose} color="gray" text="Cancel" fit />
        <DashboardButton
          onClick={() => onSubmit(studentId)}
          color="blue"
          text="Submit"
          fit
        />
      </div>
    </Dialog>
  );
};
