import s from "./styles.module.css";
import React from "react";

export const InputV2 = React.memo(
  ({
    onChange,
    name,
    value,
    onIconClick,
    placeholder,
    disabled,
    type = "text",
    icon = null,
    label = null,
    width = "100%",
  }) => {
    return (
      <div style={{ width }}>
        {label && <div className={s.inputLabel}>{label}</div>}
        <div className={s.inputContainer}>
          {icon && (
            <div
              style={{ cursor: onIconClick ? "pointer" : "default" }}
              onClick={onIconClick}
            >
              {icon}
            </div>
          )}

          <input
            type={type}
            disabled={disabled}
            name={name}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            value={value}
          />
        </div>
      </div>
    );
  }
);
