import admin, {
  axiosWithToken,
  functionBaseUrl,
} from "../../../common/firebase";

export const getUserDetails = async () => {
  const userId = admin.auth().currentUser.uid;

  const res = await axiosWithToken(`${functionBaseUrl}/api/users/${userId}`, {
    method: "get",
  });
  const subRes = !res?.data?.subscriptionType?.includes('free') ? await axiosWithToken(
    `${functionBaseUrl}/api/subscriptionByUserId/${userId}`,
    {
      method: "get",
    }
  ) : { data: {} };
  const partnerSubRes = await axiosWithToken(`${functionBaseUrl}/api/partnerSub/user/${userId}`,
    {
      method: "get",
    }
  );

  if (res.data?.subscriptionType?.includes("enterprise")) {
    const ent = await axiosWithToken(
      `${functionBaseUrl}/api/usersEnterprise/${userId}`,
      {
        method: "get",
      }
    );
    return { ...res.data, sub: subRes.data.data, partnerSub: partnerSubRes?.data, ent: ent.data?.userInfo, id: userId };
  } else {
    return { ...res.data, sub: subRes.data.data, partnerSub: partnerSubRes?.data, id: userId };
  }
};

export const updateUserDetails = async ({
  userName,
  picture,
  password,
  email,
}) => {
  const userId = admin.auth().currentUser.uid;

  let updatePictureUrl = null;

  if (picture) {
    const pictureRef = admin
      .storage()
      .ref()
      .child(`profile-pictures/${userName}-${Date.now()}`);
    try {
      await pictureRef.put(picture);
      updatePictureUrl = await pictureRef.getDownloadURL();
    } catch (error) {
      console.error("Error uploading file: ", error);
      throw new Error("Failed to upload image");
    }
  }
  const updatedData = { userName };
  if (updatePictureUrl) {
    updatedData.picture = updatePictureUrl;
  }

  const splitUserName = userName.split(" ");
  if (splitUserName.length > 1) {
    updatedData.firstName = splitUserName[0];
    updatedData.lastName = splitUserName[1];
  }

  await admin.firestore().collection("users").doc(userId).update(updatedData);

  if (password) {
    await axiosWithToken(functionBaseUrl + "/api/password", {
      method: "PATCH",
      data: {
        id: userId,
        pass: password,
        confirmPass: password,
      },
    });

    await admin.auth().signInWithEmailAndPassword(email, password);
  }
};

export const getCardNumber = async (email) => {
  const res = await axiosWithToken(
    functionBaseUrl + "/api/stripePaymentMethods",
    {
      method: "POST",
      data: { email },
    }
  );

  return res.data;
};
