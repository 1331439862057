import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Sale as SaleLayout,
} from "./layouts";
import { userRole } from "./common/roles";

import {
  Dashboard as DashboardView,
  Account as AccountView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  ForgotPassword as ForgotPasswordView,
  ResetPassword as ResetPasswordView,
  EmailVerification as EmailVerificationView,
  SessionDetails as SessionDetailsView,
  CreateSession as CreateSessionView,
  EditSession as EditSessionView,
  Search as SearchView,
  VideoUpload as VideoUploadView,
  Help as HelpView,
  Privacy as PrivacyView,
  TermsOfService as TOSView,
  RoleReview as RoleReviewView,
  Invites as InvitesView,
  Plans as PlansView,
  ReportedIssues as ReportedIssuesView,
  Labeling as LabelingDetailsView,
  CreateLabelingRequest as CreateLabelingRequestView,
  CreateLabelingJob as CreateLabelingJobView,
  EditLabelingRequest as EditLabelingRequestView,
  EditLabelingJob as EditLabelingJobView,
  Students as StudentsView,
  Learning as LearningView,
  Summary as SummaryView,
  Coupons as CouponsView,
  Reports as ReportsView,
  Devices as DevicesView,
  ContentManagement as ContentManagementView,
  CustomTokenAuth as CustomTokenAuthView,
  RemoteControlSession as RemoteControlSessionView,
  PracticeGuide as PracticeGuideView,
  QuestionnairesStatistics as QuestionnairesStatisticsView,
} from "./views";
import { Bootcamp as BootcampView } from "./views/bootcamp";
import { Enterprise as EnterpriseView } from "./views/enterprise";
import { EnterpriseInstructors as EnterpriseInstructorsView } from "./views/enterprise/instructors";
import { EnterprisePay as EnterprisePayView } from "./views/enterprise/pay";
import { Invoices as InvoicesView } from "./views/invoices";
import { InvoiceDetail as InvoiceDetailView } from "./views/invoices/detail";
import { UpdatePayment as UpdatePaymentView } from "./views/account/updatePayment";
import { Usage as UsageView } from "./views/usage";
import { UnityPage as UnityView } from "./views/unity";
import PaymentResultView from "./views/payment_result";
import { WeeklyUserReportPage } from "./views/weekly_user_report/WeeklyUserReport";
import { Credits as CreditsView } from "./views/credits";
import { CreditsHistory as CreditsHistoryView } from "./views/credits/history/CreditsHistory";
import { AccountDetailsView } from "./views/account_details/page";
import { SwingLibrary } from "./views/swing_library";
import { StudentsTablePage } from "./views/student_dashboard/";
import { MainPage } from "./views/mainpage/page";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/sign-in" />
      <RouteWithLayout
        component={MainPage}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={AccountDetailsView}
        exact
        layout={MainLayout}
        path="/account"
      />
      {/*<RouteWithLayout
        component={UpdatePaymentView}
        exact
        layout={MainLayout}
        path="/account/payment/update"
      />*/}
      <RouteWithLayout
        component={PlansView}
        exact
        layout={SaleLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={ForgotPasswordView}
        exact
        layout={MinimalLayout}
        path="/forgot-password"
      />
      <RouteWithLayout
        component={ResetPasswordView}
        exact
        layout={MinimalLayout}
        path="/reset-password"
      />
      <RouteWithLayout
        component={EmailVerificationView}
        exact
        layout={MinimalLayout}
        path="/email-verification"
      />
      <RouteWithLayout
        component={CustomTokenAuthView}
        exact
        layout={MinimalLayout}
        path="/custom-token-auth"
      />
      <RouteWithLayout
        component={HelpView}
        exact
        layout={MainLayout}
        path="/help"
      />
      {/*<RouteWithLayout
        component={SessionDetailsView}
        exact
        layout={MainLayout}
        path="/session-details"
      />*/}
      {/*<RouteWithLayout
        component={LearningView}
        exact
        layout={MainLayout}
        path="/learning"
      />*/}
      <RouteWithLayout
        component={StudentsTablePage}
        exact
        layout={MainLayout}
        path="/students"
      />
      {/*<RouteWithLayout
        component={CreateSessionView}
        exact
        layout={MainLayout}
        path="/create-session"
      />
      <RouteWithLayout
        component={EditSessionView}
        exact
        layout={MainLayout}
        path="/edit-session"
      />
      <RouteWithLayout
        component={RoleReviewView}
        exact
        layout={MainLayout}
        path="/role-review"
        allowedRoles={[userRole.ADMIN]}
      />*/}
      <RouteWithLayout
        component={InvitesView}
        exact
        layout={MainLayout}
        path="/invites"
      />
      {/*<RouteWithLayout
        component={InvoicesView}
        exact
        layout={MainLayout}
        path="/invoices"
      />
      <RouteWithLayout
        component={CreditsView}
        exact
        layout={MainLayout}
        path="/credits"
      />
      <RouteWithLayout
        component={CreditsHistoryView}
        exact
        layout={MainLayout}
        path="/credits/history"
      />
      <RouteWithLayout
        component={InvoiceDetailView}
        exact
        layout={MainLayout}
        path="/invoices/detail/:invoiceId"
      />
      <RouteWithLayout
        component={BootcampView}
        exact
        layout={MainLayout}
        path="/bootcamp"
        allowedRoles={[userRole.ADMIN]}
      />*/}
      <RouteWithLayout
        component={EnterpriseView}
        exact
        layout={MainLayout}
        path="/enterprise"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={EnterpriseInstructorsView}
        exact
        layout={MainLayout}
        path="/enterprise/instructors"
        //allowedRoles={[userRole.SPORTSBOX_ENTERPRISE, userRole.ADMIN]}
      />
      <RouteWithLayout
        component={EnterprisePayView}
        exact
        layout={MainLayout}
        path="/enterprise/pay"
        //allowedRoles={[userRole.SPORTSBOX_ENTERPRISE, userRole.ADMIN]}
      />
      {/*<RouteWithLayout
        component={PaymentResultView}
        exact
        layout={MinimalLayout}
        path="/payment"
      />*/}
      <RouteWithLayout
        component={PlansView}
        exact
        layout={SaleLayout}
        path="/plans"
      />
      {/*<RouteWithLayout
        component={ReportedIssuesView}
        exact
        layout={MainLayout}
        path="/reported-issues"
        allowedRoles={[userRole.ADMIN]}
      />*/}
      <RouteWithLayout
        component={SummaryView}
        exact
        layout={MainLayout}
        path="/summary"
        allowedRoles={[userRole.ADMIN]}
      />
      {/*<RouteWithLayout
        component={ContentManagementView}
        exact
        layout={MainLayout}
        path="/content-management"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={ReportsView}
        exact
        layout={MainLayout}
        path="/reports"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={UsageView}
        exact
        layout={MainLayout}
        path="/usage"
        allowedRoles={[userRole.ADMIN]}
      />*/}
      <RouteWithLayout
        component={UnityView}
        exact
        layout={MainLayout}
        path="/unity"
      />
      {/*<RouteWithLayout
        component={CouponsView}
        exact
        layout={MainLayout}
        path="/coupons"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={VideoUploadView}
        exact
        layout={MainLayout}
        path="/video-upload"
      />*/}
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/welcome"
      />
      <RouteWithLayout
        component={SearchView}
        exact
        layout={MainLayout}
        path="/search"
      />
      <RouteWithLayout
        component={PrivacyView}
        exact
        layout={MinimalLayout}
        path="/privacy"
      />
      <RouteWithLayout
        component={TOSView}
        exact
        layout={MinimalLayout}
        path="/terms-of-service"
      />
      {/*<RouteWithLayout
        component={LabelingDetailsView}
        exact
        layout={MainLayout}
        path="/labeling"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={CreateLabelingRequestView}
        exact
        layout={MainLayout}
        path="/create-labelingRequest"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={CreateLabelingJobView}
        exact
        layout={MainLayout}
        path="/create-labelingJob"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={EditLabelingRequestView}
        exact
        layout={MainLayout}
        path="/edit-labelingRequest"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={EditLabelingJobView}
        exact
        layout={MainLayout}
        path="/edit-labelingJob"
        allowedRoles={[userRole.ADMIN]}
      />*/}
      <RouteWithLayout
        component={RemoteControlSessionView}
        exact
        layout={MainLayout}
        path="/remote-session"
      />
      {/*<RouteWithLayout
        component={PracticeGuideView}
        exact
        layout={MainLayout}
        path="/practice-guides"
      />
      <RouteWithLayout
        component={QuestionnairesStatisticsView}
        exact
        layout={MainLayout}
        path="/questionnaires"
      />
      <RouteWithLayout
        component={WeeklyUserReportPage}
        exact
        layout={MainLayout}
        path="/weekly-reports"
      />*/}
      <RouteWithLayout
        component={SwingLibrary}
        exact
        layout={MainLayout}
        path="/swing-library"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
