import React, { useCallback, useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import s from "./styles.module.css";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { DashboardInput } from "../search-input/SearchInput";
import { DashboardButton } from "../DashboardButton";
import CloseIcon from "@material-ui/icons/Close";
import { DashboardSelect } from "../dashboard-select/DashboardSelect";
import { checkIfUserExists, createStudent, inviteStudent } from "../../api";
import Swal from "sweetalert2";

export const AddStudentDialog = ({ open, onClose, credits, onCreate }) => {
  const [dialogState, setDialogState] = useState({
    view: "init",
    email: "",
    grantSub: false,
    studentId: null,
  });

  const InviteStudentDialogContent = () => {
    const [inviteStudentState, setInviteStudentState] = useState({
      email: "",
      grantSubscription: false,
    });

    const handleInviteStudentSubmit = useCallback(async () => {
      const result = await checkIfUserExists(inviteStudentState.email);

      if (result.userExists) {
        if (
          result?.subscriptionType !== "" &&
          !result?.subscriptionType?.includes("student")
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Cannot add a coach account as a student",
            customClass: {
              container: "my-swal",
            },
          });
          return;
        }
        setDialogState({
          view: "userFound",
          email: inviteStudentState.email,
          grantSub: inviteStudentState.grantSubscription,
          studentId: result.userId,
        });
      } else {
        setDialogState({
          view: "newUser",
          email: inviteStudentState.email,
          grantSub: inviteStudentState.grantSubscription,
          studentId: result.userId,
        });
      }
      setInviteStudentState({
        email: "",
        grantSubscription: false,
      });
    }, [inviteStudentState]);

    return (
      <div>
        <div className={s.dialogHeader}>Invite students</div>
        <div>
          Enter your student’s email to send them an invite to join you at
          Sportsbox AI.
        </div>
        <div style={{ height: "20px" }} />

        <DashboardInput
          value={inviteStudentState.email}
          onChange={(field, value) =>
            setInviteStudentState((prev) => ({ ...prev, [field]: value }))
          }
          name="email"
          label="Email address"
          icon={<MailOutlineIcon fontSize="10px" />}
        />
        <div style={{ height: "20px" }} />

        {/*<div className={s.dialogCheckboxGroup}>
          <Checkbox
            style={{ padding: 0 }}
            checked={inviteStudentState.grantSubscription}
            disabled={credits <= 0}
            onChange={(e) =>
              setInviteStudentState((prev) => ({
                ...prev,
                grantSubscription: e.target.checked,
              }))
            }
          />
          <div style={{ fontWeight: "bold" }}>
            Grant 1-month of 3D Player subscription
          </div>
        </div>
        <div style={{ marginLeft: "52px" }}>
          You have {credits} credits available.
        </div>
        <div style={{ height: "20px" }} />*/}

        <div className={s.dialogButtonGroup}>
          <DashboardButton onClick={handleClose} color="gray" text="Cancel" />
          <DashboardButton
            onClick={handleInviteStudentSubmit}
            color="blue"
            text="Add student"
          />
        </div>
      </div>
    );
  };

  const NewUserPromptDialogContent = () => {
    return (
      <div>
        <div className={s.dialogHeader}>New user</div>
        <div>No user found associated with this email.</div>
        <div style={{ height: "20px" }} />

        <div>Would you like to create an account for them?</div>
        <div style={{ height: "20px" }} />

        <div className={s.dialogButtonGroup}>
          <DashboardButton onClick={handleClose} color="gray" text="No" />
          <DashboardButton
            onClick={handleNewUserSubmit}
            color="blue"
            text="Yes"
          />
        </div>
      </div>
    );
  };

  const UserFoundPromptDialogContent = () => {
    const handleInviteSubmit = useCallback(async () => {
      await inviteStudent(dialogState.studentId, dialogState.grantSub);
      setDialogState({
        view: "",
        email: "",
        grantSub: false,
        studentId: null,
      });
      onCreate();
      onClose();
    }, [dialogState]);

    return (
      <div>
        <div className={s.dialogHeader}>User was found</div>
        <div>An account already exists associated with this email.</div>
        <div style={{ height: "20px" }} />

        <div>Would you like to add them as your student?</div>
        <div style={{ height: "20px" }} />

        <div className={s.dialogButtonGroup}>
          <DashboardButton onClick={handleClose} color="gray" text="No" />
          <DashboardButton
            onClick={handleInviteSubmit}
            color="blue"
            text="Yes"
          />
        </div>
      </div>
    );
  };

  const UserFormDialogContent = () => {
    const [newUserState, setNewUserState] = useState({
      firstName: "",
      lastName: "",
      height: null,
      gender: null,
      dominantHand: null,
    });

    const handleUserForm = (field, value) => {
      setNewUserState((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

    const handleUserFormSubmit = useCallback(async () => {
      const { firstName, lastName, height, gender, dominantHand } =
        newUserState;

      const { email, grantSub } = dialogState;
      await createStudent({
        firstName,
        lastName,
        height,
        gender,
        dominantHand,
        email,
        toGrantSubscription: grantSub,
      });
      onCreate();
      setDialogState({
        view: "init",
        email: "",
        grantSub: false,
        studentId: null,
      });
    }, [newUserState, dialogState]);

    return (
      <div>
        <div className={s.dialogHeader}>User details</div>
        <div style={{ height: "20px" }} />

        <div className={s.dialogInputGroup}>
          <DashboardInput
            value={newUserState.firstName}
            onChange={handleUserForm}
            name="firstName"
            width="170px"
            placeholder="John"
            label="First name*"
          />

          <DashboardInput
            value={newUserState.lastName}
            onChange={handleUserForm}
            name="lastName"
            width="170px"
            placeholder="Doe"
            label="Last name*"
          />
          <DashboardSelect
            label="Height"
            width="170px"
            name="height"
            value={newUserState.height}
            onChange={handleUserForm}
            options={[
              { text: "", value: null },
              { text: "4'0\"", value: "4'0\"" },
              { text: "4'1\"", value: "4'1\"" },
              { text: "4'2\"", value: "4'2\"" },
              { text: "4'3\"", value: "4'3\"" },
              { text: "4'4\"", value: "4'4\"" },
              { text: "4'5\"", value: "4'5\"" },
              { text: "4'6\"", value: "4'6\"" },
              { text: "4'7\"", value: "4'7\"" },
              { text: "4'8\"", value: "4'8\"" },
              { text: "4'9\"", value: "4'9\"" },
              { text: "4'10\"", value: "4'10\"" },
              { text: "4'11\"", value: "4'11\"" },
              { text: "5'0\"", value: "5'0\"" },
              { text: "5'1\"", value: "5'1\"" },
              { text: "5'2\"", value: "5'2\"" },
              { text: "5'3\"", value: "5'3\"" },
              { text: "5'4\"", value: "5'4\"" },
              { text: "5'5\"", value: "5'5\"" },
              { text: "5'6\"", value: "5'6\"" },
              { text: "5'7\"", value: "5'7\"" },
              { text: "5'8\"", value: "5'8\"" },
              { text: "5'9\"", value: "5'9\"" },
              { text: "5'10\"", value: "5'10\"" },
              { text: "5'11\"", value: "5'11\"" },
              { text: "6'0\"", value: "6'0\"" },
              { text: "6'1\"", value: "6'1\"" },
              { text: "6'2\"", value: "6'2\"" },
              { text: "6'3\"", value: "6'3\"" },
              { text: "6'4\"", value: "6'4\"" },
              { text: "6'5\"", value: "6'5\"" },
              { text: "6'6\"", value: "6'6\"" },
              { text: "6'7\"", value: "6'7\"" },
              { text: "6'8\"", value: "6'8\"" },
              { text: "6'9\"", value: "6'9\"" },
              { text: "6'10\"", value: "6'10\"" },
              { text: "6'11\"", value: "6'11\"" },
              { text: "7'0\"", value: "7'0\"" },
            ]}
          />
          <DashboardSelect
            label="Gender"
            width="170px"
            name="gender"
            value={newUserState.gender}
            onChange={handleUserForm}
            options={[
              { text: "", value: null },
              { text: "Male", value: "Male" },
              { text: "Female", value: "Female" },
              { text: "Prefer not to Answer", value: "Prefer not to Answer" },
            ]}
          />
          <DashboardSelect
            label="Dominant Hand"
            width="170px"
            name="dominantHand"
            value={newUserState.dominantHand}
            onChange={handleUserForm}
            options={[
              { text: "", value: null },
              { text: "Right", value: "Right" },
              { text: "Left", value: "Left" },
            ]}
          />
        </div>

        <div style={{ height: "20px" }} />

        <div className={s.dialogButtonGroup}>
          <DashboardButton onClick={handleClose} color="gray" text="Cancel" />
          <DashboardButton
            onClick={handleUserFormSubmit}
            disabled={!newUserState.firstName && !newUserState.secondName}
            color="blue"
            text="Submit"
          />
        </div>
      </div>
    );
  };

  const handleNewUserSubmit = () => {
    setDialogState((prev) => ({ ...prev, view: "userForm" }));
  };

  const renderDialogContent = () => {
    switch (dialogState.view) {
      case "newUser":
        return <NewUserPromptDialogContent />;
      case "userFound":
        return <UserFoundPromptDialogContent />;
      case "userForm":
        return <UserFormDialogContent />;
      default:
        return <InviteStudentDialogContent />;
    }
  };

  const handleClose = () => {
    onClose();
    setDialogState((prev) => ({ ...prev, view: "init" }));
  };

  const useStyles = makeStyles({
    paper: {
      overflowY: "visible",
    },
  });
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="simple-dialog-title">
        <div className={s.dialogTitle}>
          <div className={s.dialogTitleIcon}>
            <PersonAddIcon />
          </div>
          <div onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
      </DialogTitle>
      <div className={s.dialogContent}>{renderDialogContent()}</div>
    </Dialog>
  );
};
