import React, { Component } from "react";
import PropTypes from "prop-types";
import { functionBaseUrl, axiosWithToken } from "../../common/firebase";
import firebase from "../../common/firebase";
import { withStyles } from "@material-ui/styles";
import {
  Grid,
  Container,
  Button,
  Avatar,
  Typography,
  CssBaseline,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  Box,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { AccountNotFound } from "../account/components";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import Swal from "sweetalert2";
import AddStudent from "../students/AddStudent";
import AddStudentAdmin from "../students/AddStudentAdmin";
import withReactContent from "sweetalert2-react-content";
import NoSubscriptionDialog from "../../components/NoSubscriptionDialog";
import { ImportStudentsEnterprise } from "../students/ImportStudentsEnterprise";
import { AddCredit } from "./AddCredit";
import { AddSubscriptionToUser } from "./AddSubscriptionToUser";
import moment from "moment";
import { listOfSubscriptions } from "../../common/envConfig";
const MySwal = withReactContent(Swal);

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paperReceived: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "60%",
  },
  paperSent: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "60%",
  },
  inviteButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(4),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {
    // This is a link component surrounding a button component
    width: "100%",
  },
  linkedButton: {
    // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
});

/*const RejectButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);*/

class Invites extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser;
    super(props);
    this.state = {
      uid: firebaseUser.uid,
      error: "",
      loading: true,
      role: "",
      userName: "",
      email: "",
      invitesSent: [],
      invitesReceived: [],
      roleCheck: "",
      coachInviteCount: 0,
      studentInviteCount: 0,
      popUpOpen: false,
      popUpStudentOpen: false,
      subData: {},
      subscriptionType: "",
      value: 0,
      modalEmail: "",
      modalMonths: 0,
      modalName: "",
      modalRole: "",
      userEmail: "",
      coachEmail: "",
      coaches: [],
      subEmail: "",
      subPeriod: 0,
      partnerSubPlan: listOfSubscriptions.DODGERS,
      partnerSubAction: "add",
      subPlan: "development_sportsbox_pro_lite_monthly",
      noSubscription: false,
      certification: "Sportsbox 3D Baseball Level 1 Certification",
      addStudentPopup: false,
      bootcampUser: false,
      creditPopup: false,
    };
  }

  componentDidMount() {
    if (!this.state.uid) {
      this.setState({ error: "Please log in to view invites" });
      this.setState({ loading: false });
    } else {
      firebase
        .firestore()
        .collection("users")
        .doc(this.state.uid)
        .get()
        .then(async (userDoc) => {
          if (!userDoc.exists && this.state.uid !== "") {
            console.log("No matching documents for UID: " + this.state.uid);
            this.setState({ error: "The signed in user does not exist" });
          }
          this.setState({
            email: userDoc.data().email,
            role: userDoc.data().role,
            userName: userDoc.data().firstName + " " + userDoc.data().lastName,
            subscriptionType: userDoc.data().subscriptionType,
            loading: false,
          });
          if (
            !userDoc.data().subscriptionType.includes("student") ||
            userDoc.data().role === "admin"
          ) {
            this.setState({ value: 1 });
            this.subscriptionStatus();
          }
          let tempCoaches = [];
          if (userDoc.data().subscriptionType.includes("enterprise")) {
            const coaches = await firebase
              .firestore()
              .collection("users")
              .where(
                "enterpriseAccountId",
                "==",
                userDoc.data().enterpriseAccountId
              )
              .get();
            coaches.forEach(function (doc) {
              tempCoaches.push({ ...doc.data(), id: doc.id });
            });
            this.setState({ coaches: tempCoaches });
          }

          await axiosWithToken(
            functionBaseUrl + "/api/allInvitesToInvitee/" + this.state.email,
            {
              method: "get",
            }
          )
            .then(async (response) => {
              let invites = [];

              for (const invite of response.data) {
                const user = await firebase
                  .firestore()
                  .collection("users")
                  .doc(invite.senderId)
                  .get();
                invites.push({ sender: user.data(), ...invite });
              }

              this.setState({
                loading: false,
                invitesReceived: invites,
                invitesReceivedCount: invites.length,
              });
            })
            .catch((err) => {
              console.log(err);
            });

          this.setState({ loading: false });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  subscriptionStatus = () => {
    axiosWithToken(functionBaseUrl + "/api/verifySub/" + this.state.uid, {
      method: "post",
    })
      .then((response) => {
        this.setState({
          subData: response.data.data,
        });
        if (this.state.role !== "admin" && response.data.success === false) {
          this.setState({ noSubscription: true });
        }
        if (
          Object.keys(response.data.data).length > 0 ||
          this.state.role === "admin"
        ) {
          axiosWithToken(
            functionBaseUrl + "/api/invitesBySender/" + this.state.uid,
            {
              method: "get",
            }
          )
            .then((response) => {
              this.setState({
                loading: false,
                invitesSent: response.data,
              });
            })
            .catch((err) => {
              this.setState({
                loading: false,
                error: "You are not authorized to access this page.",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  studentInviteLeft = () => {
    if (
      JSON.stringify(this.state.subData) !== undefined &&
      JSON.stringify(this.state.subData) !== "{}"
    ) {
      let productId = this.state.subData.productId;
      if (productId.includes("lite")) {
        return 15 - this.state.studentInviteCount;
      } else if (productId.includes("plus")) {
        return 25 - this.state.studentInviteCount;
      } else if (productId.includes("premium")) {
        return "Unlimited";
      } else if (productId.includes("enterprise")) {
        return "Unlimited";
      }
    } else {
      return 0;
    }
  };

  studentInviteLimit = () => {
    if (
      JSON.stringify(this.state.subData) !== undefined &&
      JSON.stringify(this.state.subData) !== "{}"
    ) {
      let productId = this.state.subData.productId;
      if (productId.includes("lite")) {
        return 15;
      } else if (productId.includes("plus")) {
        return 25;
      } else if (productId.includes("premium")) {
        return "Unlimited";
      } else if (productId.includes("enterprise")) {
        return "Unlimited";
      }
    } else {
      return 10;
    }
  };

  approveRequest = (requestId) => (event) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Accept Invite</p>',
      html: "<p>Are you sure you want to accept this invite?</p>",
      icon: "warning",
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonColor: "red",
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosWithToken(functionBaseUrl + "/api/approveInvite/" + requestId, {
            method: "POST",
          })
            .then(() => {
              let invitesReceived = [...this.state.invitesReceived];
              var requestIndex = this.state.invitesReceived
                .map((invite) => invite.id)
                .indexOf(requestId);
              invitesReceived.splice(requestIndex, 1);
              this.setState({ invitesReceived: invitesReceived });
              Swal.fire({ icon: "success" });
            })
            .catch((err) => {
              Swal.fire({ icon: "error", text: err });
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  rejectRequest = (requestId) => (event) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Reject Invite</p>',
      html: "<p>Are you sure you want to reject this invite?</p>",
      icon: "warning",
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonColor: "red",
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosWithToken(functionBaseUrl + "/api/rejectInvite/" + requestId, {
            method: "post",
          }).then((response) => {
            console.log(response);
            let invitesReceived = [...this.state.invitesReceived];
            var requestIndex = this.state.invitesReceived
              .map((invite) => invite.id)
              .indexOf(requestId);
            invitesReceived.splice(requestIndex, 1);

            let invitesSent = [...this.state.invitesSent];
            var sentIndex = this.state.invitesSent
              .map((invite) => invite.id)
              .indexOf(requestId);
            invitesSent.splice(sentIndex, 1);
            this.setState({
              invitesReceived: invitesReceived,
              invitesSent: invitesSent,
            });

            window.location.reload();
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  requesterRole = (invite) => {
    let role = "";
    if (invite && invite.role) {
      role = invite.role;
    }
    return role;
  };

  inviteValidity = (invite) => {
    let date = "";
    if (invite && invite.expiredIn) {
      date = this.dateDisplay(invite.expiredIn);
    }
    return date;
  };

  dateDisplay = (date) => {
    return moment(String(date), "x").format("MM/DD/YYYY");
  };

  closePopUp = () => {
    this.setState({ popUpOpen: false });
  };

  openPopUpStudent = () => {
    if (
      this.state.role === "admin" ||
      this.state.subscriptionType.includes("enterprise") ||
      this.state.subscriptionType.includes("premium")
    ) {
      this.setState({ popUpOpen: true, roleCheck: "Sportsbox-User" });
      return false;
    }
    if (JSON.stringify(this.state.subData) === "{}") {
      Swal.fire({
        title:
          '<p style="font-size:70%;">You cannot add students with your plan!</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return false;
    }
    if (
      Object.keys(this.state.subData).length > 0 &&
      this.state.subData.productId.includes("student")
    ) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">You cannot add students with your plan!</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return false;
    }
    if (this.state.subscriptionType.includes("enterprise")) {
      this.setState({ popUpOpen: true, roleCheck: "Sportsbox-User" });
    } else if (
      Object.keys(this.state.subData).length > 0 &&
      this.state.studentInviteCount < this.studentInviteLimit()
    ) {
      this.setState({ popUpOpen: true, roleCheck: "Sportsbox-User" });
    } else {
      Swal.fire(
        '<p style="font-size:70%;">You have exceeded your Student invite limit</p>'
      );
      return false;
    }
  };

  closePopUpStudent = () => {
    this.setState({ popUpStudentOpen: false });
  };

  openPopUpStudentAdmin = () => {
    if (this.state.role === "admin") {
      this.setState({ popUpStudentOpen: true });
    }
  };

  openPopUpCoach = () => {
    //this.checkCoachInviteCount()
    //console.log('coachInvCount: ' + this.state.coachInviteCount)
    if (this.state.role === "admin") {
      this.setState({ popUpOpen: true, roleCheck: "Sportsbox-Instructor" });
      return false;
    }
    this.setState({ popUpOpen: true, roleCheck: "Sportsbox-Instructor" });
    /*if (this.state.role === 'Sportsbox-Instructor' && this.state.coachInviteCount < 5) {
      this.setState({popUpOpen: true, roleCheck: 'Sportsbox-Instructor'})
    } else {
      Swal.fire('<p style="font-size:70%;">You have exceeded your Instructor invite limit</p>')
      return false
    }*/
  };

  validateEmail = (email) => {
    const verify = /\S+@\S+\.\S+/;
    return verify.test(email);
  };

  addStudentToACoach = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add Student To A Coach</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: true,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                label="Coach Email"
                onChange={this.handleChange("coachEmail")}
                defaultValue={this.state.coachEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then((response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.coachEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter Coach Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.coachEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid Coach Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        axiosWithToken(functionBaseUrl + "/api/userCoach", {
          method: "POST",
          data: {
            userEmail: this.state.userEmail,
            coachEmail: this.state.coachEmail,
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "", coachEmail: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  closePopUpAddSubscriptionToUser = () => {
    this.setState({ popUpAddSubscriptionToUserOpen: false });
  };

  openPopUpAddSub = () => {
    this.setState({ popUpAddSubscriptionToUserOpen: true });
  };

  openPopUpAddPartnerSub = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: true,
      showCancelButton: true,
      customClass: {
        container: "my-swal",
      },
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid
              item
              //sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("subEmail")}
                defaultValue={this.state.subEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="partnerSubPlan-select">Plan*</InputLabel>
                <Select
                  id="partnerSubPlan"
                  label="Plan*"
                  labelId="partnerSubPlan-select"
                  name="partnerSubPlan"
                  defaultValue={this.state.partnerSubPlan}
                  onChange={this.handleChange("partnerSubPlan")}
                  MenuProps={{
                    disablePortal: true,
                  }}
                >
                  <MenuItem value={listOfSubscriptions.DODGERS}>
                    Dodgers
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="partnerSubAction-select">Action*</InputLabel>
                <Select
                  id="partnerSubAction"
                  label="Action*"
                  labelId="partnerSubAction-select"
                  name="partnerSubAction"
                  defaultValue={this.state.partnerSubAction}
                  onChange={this.handleChange("partnerSubAction")}
                  MenuProps={{
                    disablePortal: true,
                  }}
                >
                  <MenuItem value={"add"}>Add</MenuItem>
                  <MenuItem value={"remove"}>Remove</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (this.state.subEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.subEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.partnerSubPlan.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please select a Plan</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        if (this.state.partnerSubAction === "add") {
          await axiosWithToken(functionBaseUrl + "/api/addPartnerSub", {
            method: "POST",
            data: {
              email: this.state.subEmail.toLowerCase().replace(/ /g, ""),
              productId: this.state.partnerSubPlan,
              months: 1,
              subLabel: "",
            },
          })
            .then((creds) => {
              if (creds.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' + creds.data.message + "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">Subscription successfully added for: ' +
                    this.state.subEmail +
                    "</p>",
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 5000,
                }).then(() => {
                  this.setState({
                    subEmail: "",
                    partnerSubPlan: listOfSubscriptions.DODGERS,
                  });
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (this.state.partnerSubAction === "remove") {
          await axiosWithToken(functionBaseUrl + "/api/removePartnerSub", {
            method: "POST",
            data: {
              email: this.state.subEmail.toLowerCase().replace(/ /g, ""),
              productId: this.state.partnerSubPlan,
            },
          })
            .then((creds) => {
              if (creds.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' + creds.data.message + "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">Subscription successfully removed</p>',
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 3000,
                }).then(() => {
                  this.setState({
                    subEmail: "",
                    partnerSubPlan: listOfSubscriptions.DODGERS,
                    partnerSubAction: "add",
                  });
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  };

  openPopUpCreateAcc = () => {
    if (this.state.role !== "admin") {
      return false;
    }
    MySwal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: true,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid
              item
              //sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("subEmail")}
                defaultValue={this.state.subEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid
              item
              //sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                required
                label="Name"
                onChange={this.handleChange("modalName")}
                defaultValue={this.state.modalName}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid
              item
              //sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                required
                label="Subscription Period"
                onChange={this.handleChange("subPeriod")}
                defaultValue={this.state.subPeriod}
                variant="outlined"
                type="Number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">month(s)</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await axiosWithToken(functionBaseUrl + "/api/userAuth", {
            method: "POST",
            data: {
              email: this.state.subEmail,
              password: "ilovegolf",
              name: this.state.modalName,
              plan:
                this.state.subPeriod === 0
                  ? listOfSubscriptions.STUDENT_FREE
                  : listOfSubscriptions.PREMIUM_MONTHLY,
              months:
                this.state.subPeriod !== "" && this.state.subPeriod !== 0
                  ? this.state.subPeriod
                  : 0,
              bootcampUser: false,
            },
          })
            .then((creds) => {
              if (creds.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">An account already exists with the email: ' +
                    this.state.subEmail +
                    "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">Account successfully created for: ' +
                    this.state.subEmail +
                    " with " +
                    this.state.subPeriod +
                    " months of subscription</p>",
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 3000,
                });
              }
              this.setState({
                subEmail: "",
                modalName: "",
                subPlan: "development_sportsbox_pro_lite_monthly",
                subPeriod: "",
                bootcampUser: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (prop) => (event) => {
    /*if (prop === 'subPeriod') {
      event.target.value < 0
        ? this.setState({ [prop]: 0 })
        : this.setState({ [prop]: event.target.value })
    } else {
      this.setState({ [prop]: event.target.value });
    }*/
    this.setState({ [prop]: event.target.value });
  };

  openPopUpCertification = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add Certification</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="subPlan-select">Certification*</InputLabel>
                <Select
                  id="certification"
                  label="Certification"
                  labelId="certification-select"
                  name="certification"
                  defaultValue={this.state.certification}
                  onChange={this.handleChange("certification")}
                >
                  <MenuItem
                    value={"Sportsbox 3D Baseball Level 1 Certification"}
                  >
                    Sportsbox 3D Baseball Level 1
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.certification.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please select a certificate</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        axiosWithToken(functionBaseUrl + "/api/addCertification", {
          method: "POST",
          data: {
            email: this.state.userEmail,
            certification: this.state.certification,
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpOnboarding = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add Onboarding Completed</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        axiosWithToken(functionBaseUrl + "/api/addOnboarding", {
          method: "POST",
          data: {
            email: this.state.userEmail,
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpClearDevices = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Clear Devices List</p>',
      allowOutsideClick: false,
      confirmButtonText: "Clear",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        await axiosWithToken(functionBaseUrl + "/api/userDevices", {
          method: "DELETE",
          data: {
            userEmail: this.state.userEmail.toLowerCase().replace(/ /g, ""),
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">Devices list successfully cleared!</p>',
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpAddUserToDevExc = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add User to Device Exceptions List</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        await axiosWithToken(
          functionBaseUrl + "/api/mobileAppSettings/addUser",
          {
            method: "PATCH",
            data: {
              email: this.state.userEmail.toLowerCase().replace(/ /g, ""),
            },
          }
        )
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">User successfully added to the device exceptions list!</p>',
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpVerifyEmail = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Verify Email Address</p>',
      allowOutsideClick: false,
      confirmButtonText: "Verify",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        const userResponse = await axiosWithToken(
          `${functionBaseUrl}/api/searchUserByEmail`,
          {
            method: "POST",
            data: { email: this.state.userEmail.toLowerCase() },
          }
        );
        if (userResponse?.data?.length === 0) {
          Swal.fire(
            '<p style="font-size:70%;">No user found with the email: <b>' +
              this.state.userEmail +
              "</b></p>"
          );
          return;
        }

        await axiosWithToken(functionBaseUrl + "/api/userEmailVerf", {
          method: "PATCH",
          data: {
            id: userResponse?.data[0]?.id,
            status: true,
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: '<p style="font-size:70%;">' + err + "</p>",
              icon: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          });
      }
      return false;
    });
  };

  openPopUpImport = () => {
    this.setState({ addStudentPopup: true });
  };

  closePopUpImport = () => {
    this.setState({ addStudentPopup: false });
  };

  openPopUpCredit = () => {
    this.setState({ creditPopup: true });
  };

  closePopUpCredit = () => {
    this.setState({ creditPopup: false });
  };

  handleAnyUserPassChange = () => {
    Swal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      html: `
        <label for="emailPass">Email:</label>
        <input id="emailPass" class="swal2-input">
        `,
      focusConfirm: false,
      confirmButtonText: "Change",
      confirmButtonColor: "green",
      showCancelButton: true,
      customClass: {
        container: "my-swal",
      },
    })
      .then((result) => {
        let userEmail = document
          .getElementById("emailPass")
          .value.toLowerCase()
          .replace(/ /g, "");
        if (result.isConfirmed) {
          axiosWithToken(functionBaseUrl + "/api/anyUserPass", {
            method: "PATCH",
            data: {
              email: userEmail,
              password: "ilovegolf",
            },
          })
            .then((response) => {
              if (response.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' +
                    response.data.message +
                    "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' +
                    userEmail +
                    ' password successfully changed to "<b>ilovegolf<b>"</p>',
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDeleteButton = () => {
    Swal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      html: `
        <label for="email">Email:</label>
        <input id="email" class="swal2-input">
        `,
      focusConfirm: false,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
      showCancelButton: true,
      customClass: {
        container: "my-swal",
      },
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosWithToken(
            functionBaseUrl +
              "/api/userByEmail/" +
              document
                .getElementById("email")
                .value.toLowerCase()
                .replace(/ /g, ""),
            {
              method: "DELETE",
            }
          )
            .then((response) => {
              if (response.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' +
                    response.data.message +
                    "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">Account successfully deleted</p>',
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 2000,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <Container component="main" className={this.props.classes.paper}>
        <CssBaseline />
        <div className={this.props.classes.paper}>
          <Avatar className={this.props.classes.avatar}>
            {this.state.value === 0 ? <CallReceivedIcon /> : <CallMadeIcon />}
          </Avatar>
          <Typography
            component="h1"
            variant="h4"
            style={{ marginBottom: "10px" }}
          >
            INVITES
          </Typography>
          <Box
            style={{ marginBottom: "2%" }}
            //sx={{ width: "50%", bgcolor: "background.paper" }}
          >
            <Tabs
              value={this.state.value}
              onChange={this.handleChange("value")}
              centered
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                label="Pending"
                onClick={() => this.setState({ value: 0 })}
              />
              {(this.state.subscriptionType.length > 0 &&
                !this.state.subscriptionType.includes("student")) ||
              this.state.role === "admin" ? (
                <Tab label="Sent" onClick={() => this.setState({ value: 1 })} />
              ) : (
                <div></div>
              )}
            </Tabs>
          </Box>
          {this.state.value === 0 ? (
            <div>
              <Card style={{ minWidth: "50%" }}>
                <CardContent>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                    >
                      <b>Total Invites Received:</b>&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                    >
                      {this.state.invitesReceived.length}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
              <div className={this.props.classes.root}>
                {this.state.invitesReceived.length === 0
                  ? "There are no invites"
                  : this.state.invitesReceived.map((invite) => (
                      <Card style={{ minWidth: "50%" }} key={invite.id}>
                        <CardHeader
                          title={
                            invite.sender.firstName +
                            " " +
                            invite.sender.lastName +
                            " (" +
                            invite.sender.email +
                            ")"
                          }
                          titleTypographyProps={{
                            variant: "h5",
                            color: "primary",
                          }}
                        />
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginTop: "-10px",
                          }}
                        >
                          <i>
                            {"is inviting your for the role of " +
                              this.requesterRole(invite)}
                          </i>
                        </Typography>
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginBottom: "10px",
                          }}
                        >
                          <b>Valid Till: </b>
                          {" " + this.inviteValidity(invite)}
                        </Typography>
                        <Divider />
                        <CardContent>
                          <Grid container justify="space-between">
                            <Button
                              style={{ margin: "0 auto", display: "flex" }}
                              variant="contained"
                              color="primary"
                              justify="center"
                              onClick={this.approveRequest(invite.id)}
                            >
                              Accept
                            </Button>

                            <Button
                              style={{ margin: "0 auto", display: "flex" }}
                              variant="contained"
                              color="secondary"
                              justify="center"
                              onClick={this.rejectRequest(invite.id)}
                            >
                              Reject
                            </Button>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))}
              </div>
            </div>
          ) : (
            <div>
              <Card style={{ minWidth: "50%" }}>
                <CardContent>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                    >
                      <b>Student Invites Sent:</b>&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                    >
                      {this.state.studentInviteCount}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                    >
                      <b>Student Invites Left:</b>&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                      style={{ marginBottom: "10px" }}
                    >
                      {this.studentInviteLeft()}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
              <div className={this.props.classes.inviteButtons}>
                <Button
                  variant="contained"
                  color="primary"
                  className={this.props.classes.linkedButton}
                  onClick={this.openPopUpStudent}
                >
                  ADD A NEW STUDENT
                </Button>
                {this.state.role === "admin" && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpStudentAdmin}
                    >
                      ADD A NEW STUDENT (ADMIN)
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpCreateAcc}
                    >
                      CREATE A NEW ACCOUNT
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.addStudentToACoach}
                    >
                      ADD STUDENT TO A COACH
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpAddSub}
                    >
                      ADD SUBSCRIPTION TO A USER
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpAddPartnerSub}
                    >
                      ADD/REMOVE PARTNER SUBSCRIPTION
                    </Button>
                    {/*<Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpCertification}
                    >
                      ADD CERTIFICATION
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpOnboarding}
                    >
                      ADD ONBOARDING COMPLETED
                    </Button>*/}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpImport}
                    >
                      IMPORT STUDENT ACCOUNTS FOR COACH
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpClearDevices}
                    >
                      CLEAR USER'S DEVICES LIST
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpAddUserToDevExc}
                    >
                      ADD USER TO DEVICE EXCEPTIONS LIST
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpVerifyEmail}
                    >
                      VERIFY EMAIL ADDRESS
                    </Button>
                    {/*<Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpCredit}
                    >
                      ADD/REMOVE CREDITS
                    </Button>*/}
                    <Button
                      className={this.props.classes.linkedButton}
                      variant="contained"
                      color="secondary"
                      style={{ backgroundColor: "green" }}
                      onClick={this.handleAnyUserPassChange}
                    >
                      Change Any Account's Password
                    </Button>
                    <Button
                      className={this.props.classes.linkedButton}
                      variant="contained"
                      color="secondary"
                      style={{ backgroundColor: "red" }}
                      onClick={this.handleDeleteButton}
                    >
                      Delete Any Account
                    </Button>
                    {this.state.addStudentPopup && (
                      <ImportStudentsEnterprise
                        open={this.state.addStudentPopup}
                        close={this.closePopUpImport}
                        uid={this.state.uid}
                        name={this.state.userName}
                        admin={true}
                      />
                    )}
                  </>
                )}
              </div>
              <AddStudent
                open={this.state.popUpOpen}
                onClose={this.closePopUp}
                name={this.state.userName}
                uid={this.state.uid}
                roleCheck={this.state.roleCheck}
                subscriptionType={this.state.subscriptionType}
                coaches={this.state.coaches}
              />
              <AddStudentAdmin
                open={this.state.popUpStudentOpen}
                onClose={this.closePopUpStudent}
                name={this.state.userName}
                uid={this.state.uid}
                roleCheck="Sportsbox-User"
              />
              {this.state.popUpAddSubscriptionToUserOpen && (
                <AddSubscriptionToUser
                  open={this.state.popUpAddSubscriptionToUserOpen}
                  close={this.closePopUpAddSubscriptionToUser}
                />
              )}
              <AddCredit
                open={this.state.creditPopup}
                close={this.closePopUpCredit}
              />
              <div className={this.props.classes.root}>
                {this.state.invitesSent.length === 0
                  ? "There are no invites"
                  : this.state.invitesSent.map((invite) => (
                      <Card style={{ maxWidth: "100%" }} key={invite.id}>
                        <CardHeader
                          title={
                            invite.inviteeName + " (" + invite.invitee + ")"
                          }
                          titleTypographyProps={{
                            variant: "h5",
                            color: "primary",
                          }}
                        />
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginTop: "-10px",
                          }}
                        >
                          <i>
                            {"has been invited for the role of " +
                              this.requesterRole(invite)}
                          </i>
                        </Typography>
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginBottom: "10px",
                          }}
                        >
                          {invite.status === "pending" &&
                            invite.expiredIn > Date.now() && (
                              <>
                                <b>Valid Till: </b>
                                {" " + this.inviteValidity(invite)}
                              </>
                            )}
                          {invite.status === "pending" &&
                            invite.expiredIn <= Date.now() && (
                              <>
                                <b>Expired</b>
                              </>
                            )}
                          {invite.status !== "pending" && (
                            <b>
                              {invite.status.charAt(0).toUpperCase() +
                                invite.status.slice(1)}
                            </b>
                          )}
                        </Typography>
                        <Divider />
                        {invite.status === "pending" &&
                          invite.expiredIn > Date.now() && (
                            <CardContent>
                              <Button
                                style={{ margin: "0 auto", display: "flex" }}
                                variant="contained"
                                color="secondary"
                                onClick={this.rejectRequest(invite.id)}
                              >
                                Cancel
                              </Button>
                            </CardContent>
                          )}
                      </Card>
                    ))}
              </div>
            </div>
          )}
        </div>
        {/* Error screen*/}
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style={{
            display: this.state.error && !this.state.loading ? "block" : "none",
          }}
        >
          <AccountNotFound error={{ error: this.state.error }} />
        </Grid>
        {this.state.noSubscription && <NoSubscriptionDialog />}
      </Container>
    );
  }
}

Invites.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(Invites);
